import React from "react";
import {
  FOOTBALL_LIVE_FIXTURES_SUBSCRIPTION,
  FootballSelector,
  LiveGamesSelector,
  useSubscription,
} from "@michaelojo/vermo-redux";
import { LeagueDetailsSelector } from "@michaelojo/vermo-redux";
import moment from "moment";
import {
  DivisionContainer,
  FilterContainer,
  PageTitle,
} from "../common/StyleComponents";
import PairSport from "../others/SelectSports/PairSport";
import { updateMatchArray } from "../../utils/helperfuntions";
import Loading from "../common/Loading";

const LiveGames = () => {
  const leaguesDetails = LeagueDetailsSelector() as any;


  //@ts-ignore
  const formattedLeague = () => {
    let formattedData = {} as any;
    //@ts-ignore
    if (leaguesDetails?.getLeagues) {
      for (const data of leaguesDetails?.getLeagues) {
        formattedData[data?.apiFootballLeagueId] = data;
      }
      return formattedData;
    }
    return [];
  };
  const { data, loading, error } = useSubscription(FOOTBALL_LIVE_FIXTURES_SUBSCRIPTION) as any;
  const liveGamesList = LiveGamesSelector() as any;
  const formattedLeagues = formattedLeague() as any;


  const liveGames = updateMatchArray(
    liveGamesList,
    formattedLeagues,
    true
  )?.map((football, key) => {
  
    return (
      <>
        <DivisionContainer key={key}>
          <div className="wrapper">
            <img src={football?.countryFlag} alt="" />
            <p className="country">{football?.country}</p>
          </div>
          <div className="wrapper">
            <img src={football?.divisionFlag} alt={football?.division} />
            <p className="division">{football?.division}</p>
          </div>
          <div></div>
        </DivisionContainer>
        <br />
        <PairSport matchDets={football?.match} data={football} />
      </>
    );
  });
  return (
    <>
      {loading && (
        <>
          <Loading skeleton={true} />
        </>
      )}
      {liveGamesList?.length ? liveGames : <></>}
      {liveGamesList?.length === 0 && !loading && (
        <PageTitle>
          <h2> No ongoing live games currently</h2>
        </PageTitle>
      )}
    </>
  );
};

export default LiveGames;
