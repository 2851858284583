import {
  createGlobalStyle,
  GlobalStyleComponent,
  DefaultTheme,
  ThemeProps,
} from "styled-components";
interface CustomTheme extends DefaultTheme {}

export const lightTheme: CustomTheme = {
  primaryColor: "#603be8",
  secondaryColor: "#5236a4 ",
  mutedColorPrimary: "#b3bfc8",
  mutedColorSecondary: "#b7bdc4",
  mutedColorTertiary: "#959ba2",
  gradient1: "linear-gradient(145deg, #f31a71 #f58389 )",
  gradient2: "linear-gradient(145deg,#3449af #601db6)",
  background: "#eef2f5",
  foreground: "#ffffff",
  navigationBackground: "#F5FAFD",
  textColor: "#000000",
  accentPrimary: "#e01430",
  accentSecondary: "#5db36d",
  accentTertiary: "#3070a0",
  subtleShadow: "0px 0px 4px rgba(222, 224, 237, 0.75)",
  authBackground: "#ffffff",
  authShadow: "1px 1px 10px 1px #97979726;",
};

export const darkTheme: CustomTheme = {
  primaryColor: "#603be8",
  secondaryColor: "#5236a4",
  mutedColorPrimary: "#373e46",
  mutedColorSecondary: "#48515c",
  mutedColorTertiary: "#6a737e",
  gradient1: "linear-gradient(145deg,#f31a71 #f58389 )",
  gradient2: "linear-gradient(145deg,#3449af #601db6)",
  background: "#1a1e23",
  foreground: "#2d3439",
  navigationBackground: "#15191c",
  textColor: "#ccccce",
  accentPrimary: "#e01430",
  accentSecondary: "#5db36d",
  accentTertiary: "#3070a0",
  subtleShadow: "",
  authBackground: "#1a1e23",
  authShadow: "",
};
export const GlobalStyle: GlobalStyleComponent<
  {},
  DefaultTheme
> = createGlobalStyle`
body {
 background:${(props) => props.theme.background}}
  *{
                transition: background 0.3s ease;
                transition-property: background, color;


    }
`;
