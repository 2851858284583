import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { ModalComponent } from "../common/ModalComponent";
import { NotificationText } from "../common/StyleComponents";
import { ButtonComponent } from "../common/Button";
import {
  AcceptedPaymentMethodSelector,
  fundWalletAction,
  getAcceptedPaymentMethod,
  getUsersWalletAction,
} from "@michaelojo/vermo-redux";
import { NotificationAlert } from "../../utils/NotificationAlert";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { Vermo } from "../common/styled";


interface ModalInterface {
  onCancel: () => void;
  visible: boolean;
}
interface Values {
  values: string;
  kind: string;
}
const FundWalletModal = ({
  onCancel,
  visible,
}: ModalInterface) => {
  const dispatch:ThunkDispatch<any, any, any> = useDispatch();

  const [loadingState, setLoadingState] = useState(false);
  const { getAcceptedPaymentMethods} =  AcceptedPaymentMethodSelector() as any

  useEffect(() => {
    dispatch(getAcceptedPaymentMethod({}))
  },[dispatch])


  const fundMethod = [
    {
      id: 1,
      name: "CRYPTO"
    },

    {
      id: 2,
      name: "FIAT"
    }
  ]


  const fundWallet = (values: { payinMethod: string; amount: number; acceptedPaymentMethodId: string }) => {
  
    const payload = {
      payinMethod: values.payinMethod,
      amount: Number(values.amount),
      acceptedPaymentMethodId: values.acceptedPaymentMethodId
    };
    setLoadingState(true);
    dispatch(fundWalletAction(payload))
      .unwrap()
      .then((originalPromiseResponse: any) => {
        setLoadingState(false);
        if (!originalPromiseResponse?.fundWallet?.status) {
          NotificationAlert(
            "error",
            "Error occured",
            originalPromiseResponse?.fundWallet?.msg
          );
        } else {
          NotificationAlert(
            "success",
            "Successful",
            originalPromiseResponse?.fundWallet?.msg
          );
          onCancel();
            const paymentUrl = originalPromiseResponse?.fundWallet?.paymentUrl;
        if (paymentUrl) {
          window.open(paymentUrl, '_blank');
        }
          dispatch(getUsersWalletAction({}));
        }
      })
      .catch((err: any) => {
        setLoadingState(false);
        NotificationAlert("error", "Error occured", err.message);
      });
  };

  const formik = useFormik({
      initialValues: {
        payinMethod: "",
        amount: 0,
        acceptedPaymentMethodId: ""
      },
      onSubmit: fundWallet
  })

  return (
    <>
      <ModalComponent visible={visible} onCancel={onCancel}>
        <div style={{ padding: "1vw", width: "100%" }}>
          <NotificationText>Fund wallet your Wallet</NotificationText>
          <br />
          <br />

          <form onSubmit={formik.handleSubmit}>
            <Vermo.TextWrapper>

            <Vermo.TextTitle>Funding Method</Vermo.TextTitle>
            <Vermo.SelectField
              size="large"
              placeholder="select funding method"
              defaultValue="select funding method"
              bordered={false}
              value={formik.values.payinMethod || undefined}
              onChange={(value) => formik.setFieldValue("payinMethod", value)}
            >
              {fundMethod?.map((item: any) => (
              <Vermo.SelectOption key={item?.id} value={item?.name}>
                {item?.name}
          </Vermo.SelectOption>
            ))}
            </Vermo.SelectField>


            <Vermo.TextTitle>Payment Method</Vermo.TextTitle>
            <Vermo.SelectField
              size="large"
              defaultValue="select payment method"
              bordered={false}
              value={formik.values.acceptedPaymentMethodId || undefined}
              onChange={(value) => formik.setFieldValue("acceptedPaymentMethodId", value)}
              placeholder="select payment method"
            >
            {getAcceptedPaymentMethods?.map((item: any) => (
              <Vermo.SelectOption key={item?.id} value={item?.id}>
                {item?.name}
          </Vermo.SelectOption>
            ))}
            </Vermo.SelectField>

            <Vermo.TextTitle>Funding Amount</Vermo.TextTitle>
            <Vermo.InputField type="text" placeholder="Enter Amount" size="large"
              name="amount"
              value={formik.values.amount}
              onChange={formik.handleChange}
            />

              <div style={{ display: "flex", width: "100%", gap: "4px"}}>
              <ButtonComponent
                  onClick={onCancel}
                  text={"Cancel"}
                  style={{ marginTop: "20px", width: "100%"}}
                  htmlType="button"
                />

          <ButtonComponent
                  loading={loadingState}
                  // onClick={handleSubmit}
                  text={"Fund Wallet"}
                  style={{ marginTop: "20px", width: "100%" }}
                  htmlType="submit"
                />
              </div>
            </Vermo.TextWrapper>
         
          </form>
        </div>
        <br />
      </ModalComponent>
    </>
  );
};

export default FundWalletModal;
