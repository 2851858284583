import React from "react";
import { Carousel as AntCarousel } from "antd";
import { device } from "../../theme/breakPoints";
import styled from "styled-components";
import baller1 from "../../asset/baller.png";
import challenge from "../../asset/challenger1.png";
import basketball from "../../asset/basketBall.png";
import { desktop } from "../../theme/fontSize";
import { ButtonComponent } from "../common/Button";
import { Link } from "react-router-dom";
const Carousel = ({ absoluteWidth }: any) => {
  return (
    <StyledCarousel
      autoplay={true}
      speed={3000}
      infinite={true}
      style={{ margin: 0, padding: 0, width: `${absoluteWidth}px` }}
      effect="fade"
    >
      <CarouselContent>
        <FirstContent>
          <div>
            <FirstText>WELCOME TO VERMO</FirstText>
            <SecondText>Home of Sports Bet</SecondText>
            <div style={{ width: "fit-content" }}>
              <Link to="/sports">
              <ButtonComponent
                style={{
                  cursor: "pointer",
                  background: "linear-gradient(145deg, #f31a71, #f58389 )",
                  fontWeight: 700,
                  marginTop: 15,
                  width: "fit-content",
                }}
                text={"VIEW ALL SPORTS"}
              />
              </Link>
            </div>
          </div>
        </FirstContent>
        <FirstImage src={baller1} alt="baller" />
      </CarouselContent>
      <CarouselContent>
        <FirstContent
          style={{
            background: "linear-gradient(145deg,#3449af,#601db6)",
          }}
        >
          <div>
            <FirstText>CHALLENGE AND WIN </FirstText>
            <SecondText>Create a bet and challenge your friends</SecondText>
            <div style={{ width: "fit-content", zIndex: 1000 }}>
              <Link to="/challenges">
              <ButtonComponent
                style={{
                  background: "red",
                  fontWeight: 700,
                  marginTop: 15,
                  width: "fit-content",
                }}
                text={"CREATE A CHALLENGE"}
              />
              </Link>
            </div>
          </div>
        </FirstContent>
        <FirstImage style={{ zIndex: 0 }} src={challenge} alt="challenge" />
      </CarouselContent>
      <CarouselContent>
        <FirstContent style={{ backgroundColor: "#5236a4" }}>
          <div>
            <FirstText>VARIETY OF SPORTS </FirstText>
            <SecondText>Choose from our variety of sports</SecondText>
            {/* <div style={{ width: "fit-content" }}>
              <ButtonComponent
                style={{
                  background: "red",
                  fontWeight: 700,
                  marginTop: 15,
                  width: "fit-content",
                }}
                text={"CREATE A CHALLENGE"}
              />
            </div> */}
          </div>
        </FirstContent>
        <FirstImage src={basketball} alt="basket" />
      </CarouselContent>
    </StyledCarousel>
  );
};

export default Carousel;

const StyledCarousel = styled(AntCarousel)`
  display: block;
`;
const CarouselContent = styled.div`
  height: 200px;
  @media ${device.tablet} {
    height: clamp(250px, 30vh, 350px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }
`;
const FirstImage = styled.img`
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  height: 100%;

  @media ${device.laptop} {
    right: 10%;
    height: clamp(250px, 30vh, 350px);
  }
`;
const FirstContent = styled.div`
  width: 100%;
  border-radius: 5px;
  align-items: center;
  background-color: #3a5ae5;
  height: clamp(200px, 25vh, 350px);
  display: flex;
  padding: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
`;
const FirstText = styled.p`
  margin: 0;
  z-index: 10;
  color: white;
  font-weight: 600;
  font-size: 16px;

  @media ${device.tablet} {
    font-size: clamp(30px, 2.5vw, 30px);
    /* color: ${(props) => props.theme.textColor}; */
    font-family: Montserrat;
  }
`;
const SecondText = styled.p`
  z-index: 10;
  color: white;
  font-size: 14px;
  font-weight: 400;
  width: 70%;

  margin: 0;
  @media ${device.tablet} {
    width: 100%;
    font-size: ${desktop.medium};
    /* color: ${(props) => props.theme.textColor}; */
    /* font-family */
    font-weight: 700;
  }
`;
