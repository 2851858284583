import { Button, Result } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "./style";

const Successful = () => {
  const navigate = useNavigate();

  const goToWallet = () => {
    navigate("/wallet");
  };
  return (
    <Container>
      <Result
        status="success"
        title="Successful Payment!"
        subTitle="Your payment was successful."
        extra={[
          <Button onClick={goToWallet} type="primary" key="console">
            Go to Wallet
          </Button>,
        ]}
      />
    </Container>
  );
};

export default Successful;
