import { LoadingOutlined } from "@ant-design/icons";
import { Result } from "antd";
import React from "react";
import styled from "styled-components";

const LoadingScreen = () => {
  return (
    <Container>
      <Result
        icon={<LoadingOutlined style={{ color: "#603be8" }} color="#603be8" />}
        title={<Text>Loading user settings, please wait... </Text>}
      />
    </Container>
  );
};

export default LoadingScreen;

const Container = styled.div`
  position: absolute;
  opacity: 0.7;
  z-index: 10;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;

  align-items: center;
  background-color: ${(props) => props.theme.background};
`;
export const Text = styled.h1`
  font-size: 20px;
  font-weight: 500;
  font-style: Montserrat;
  color: ${(props) => props.theme.textColor};
`;
