import { LoadingOutlined } from "@ant-design/icons";
import { Skeleton } from "antd";
import React from "react";
enum SkeltonType {
  Avatar = "avatar",
}
interface LoadingProp {
  skeleton?: boolean;
  type?: SkeltonType;
  skeletonList?: boolean;
}
const Loading = ({ skeleton, type }: LoadingProp) => {
  return (
    <div>
      {!skeleton ? (
        <>
          <LoadingOutlined style={{ color: "#603be8" }} />
        </>
      ) : (
        <>
          <Skeleton active={true} round={false}>
            {Array.from({ length: 3 })}
          </Skeleton>
        </>
      )}
    </div>
  );
};

export default Loading;
