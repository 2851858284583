import React from "react";
import styled from "styled-components";
import { device } from "../../theme/breakPoints";
import { desktop, mobile } from "../../theme/fontSize";
interface DropDownType {
  onChange?: React.ChangeEventHandler<HTMLSelectElement> | any;
  values?: any;
  required?: boolean;
  placeholder?: string;
  label?: string;
  name?: string;
  field?: string;
  error?: string;
  defaultValue?: any;
}
const DropDown = ({
  label,
  onChange,
  placeholder,
  required,
  values,
  name,
  field,
  error,
  defaultValue,
}: DropDownType) => {
  const [focus, setFocus] = React.useState(false);

  return (
    <div style={{ marginBottom: 15 }}>
      {label && <Label>{label}</Label>}
      <Container
        focusProp={focus}
        onBlur={() => setFocus(false)}
        onFocus={() => setFocus(true)}
      >
        <Dropdown
          value={defaultValue}
          name={name}
          placeholder={placeholder}
          required={required ? true : false}
          onChange={(e) =>
            field ? onChange(field, e?.target?.value) : onChange(e.target.value)
          }
        >
          <Option disabled selected value="">
            {placeholder}
          </Option>
          {values?.map((value: any) => (
            <Option
              // defaultValue={defaultValue}
              // selected={value === defaultValue}
              // defaultValue={values[1].label}
              key={value}
              value={value}
            >
              {value}
            </Option>
          ))}
        </Dropdown>
      </Container>
      {error && <Error>{error} !</Error>}
    </div>
  );
};
export const Error = styled.h5`
  margin: 0px 0 0 12px;
  font-weight: 300;
  font-size: ${mobile.small};
  color: red;
  @media ${device.laptop} {
    font-size: ${desktop.small};
  }
`;
export default DropDown;
interface ContainerProp {
  focusProp?: boolean;
}

export const Dropdown = styled.select`
  /* &&& { */
  border: none;
  background: ${(props) => props.theme.foreground};
  width: 100%;
  padding: 5px 10px;
  outline: none;
  height: 100%;
  color: ${(props) => props.theme.textColor};
  /* font-size: 14px !important; */
  ::placeholder {
    color: ${(props) => props.theme.mutedColorSecondary};
  }
  @media ${device.laptop} {
    /* background-color: re; */
    font-size: ${desktop.small} !important;
    /* height: 2.5vw; */
    cursor: pointer;
    &:focus,
    &:active {
      font-size: ${desktop.small};
      outline: none;
      border: none;
    }
    /* } */
  }
`;
export const Container = styled.div<ContainerProp>`
  width: 100%;
  height: 40px;
  border: ${(props) =>
    `1px solid ${
      props.focusProp
        ? props.theme.primaryColor
        : props.theme.mutedColorSecondary
    }`};
  border-radius: 4px;
  padding: 0vw 0vw;
  display: flex;
  align-items: center;
  margin: 0;
  @media ${device.laptop} {
    height: 2.5vw;
  }
`;
export const Label = styled.h5`
  margin-bottom: 0;
  font-weight: 300;
  font-size: ${mobile.normal};
  color: ${(props) => props.theme.mutedColorTertiary};
  @media ${device.laptop} {
    font-size: ${desktop.small};
  }
`;
export const Option = styled.option`
  background-color: ${(props) => props.theme.background} !important;
  font-size: 14px;
  ::placeholder {
    color: ${(props) => props.theme.mutedColorSecondary};
  }
  @media ${device.laptop} {
    font-size: ${desktop.small} !important;
  }
`;
