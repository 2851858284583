import React from "react";
import useOnScreen from "../../utils/useOnScreen";

interface Props {
  id?: number | string;
  fetchMore: () => void;
}

export const FetchMore = ({ fetchMore }: Props) => {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const isEndOFScreen = useOnScreen(ref);

  React.useEffect(() => {
    if (isEndOFScreen) {
      console.log("fetching more");
      fetchMore();
    }
  }, [isEndOFScreen]);

  return <div ref={ref}></div>;
};
