export const formatDate = (timestamp: Date | string): string => {
    const date = new Date(timestamp);
    
    if (isNaN(date.getTime())) {
        return 'N/A';
    }

    const options: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
    };

    return new Intl.DateTimeFormat('en-US', options).format(date);
};


export const formatTime = (timestamp: Date | string): string => {
    const date = new Date(timestamp);

    if (isNaN(date.getTime())) {
        return 'N/A';
    }

    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();

    const period = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes > 0 ? `:${minutes.toString().padStart(2, '0')}` : '';

    return `${formattedHours}${formattedMinutes}${period}`;
};
