import { Col } from "antd";
import styled from "styled-components";
import { device } from "../../theme/breakPoints";
import { desktop } from "../../theme/fontSize";
interface CardInterface {
  changeProp: boolean;
  iconColor?: string;
  titleColor?: string;
}

export const WalletContainer = styled(Col)``;

export const WalletCard = styled.div<CardInterface>`
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  background: ${(props) =>
    !props.changeProp
      ? props.theme.foreground
      : "linear-gradient(195deg,#f31a71,#601db6)"};
  box-shadow: ${(props) => props.theme.subtleShadow};
  @media ${device.laptop} {
    width: 95%;
    height: 100%;
  }
  .icon {
    color: ${(props) =>
      props.iconColor ? props.iconColor : props.theme.textColor};
    font-size: 25px;
    width: fit-content;
    height: fit-content;
  }
  .title {
    margin: 0;
    color: ${(props) =>
      props.titleColor ? props.titleColor : props.theme.textColor};
    font-weight: 500;
    font-size: ${desktop.small};
    font-family: Montserrat;
  }
  .price {
    margin: 0;
    color: ${(props) =>
      props.titleColor ? props.titleColor : props.theme.textColor};
    font-weight: 600;
    font-size: ${desktop.medium};
    font-family: Montserrat;
    span {
      margin-left: 1px;
      font-size: ${desktop.small};
      font-weight: 400;
    }
  }
`;
export const ComingSoon = styled.div`
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: inherit;
  opacity: 0.95;
  z-index: 2;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.mutedColorPrimary};
  .text {
    margin: 0;
    color: ${(props) => props.theme.textColor};
    font-weight: 500;
    font-size: ${desktop.small};
    font-family: Montserrat;
  }
`;
export const Loading = styled.div`
  /* text-align: center;
  display: flex;
  align-items: center; */

  p {
    color: ${(props) => props.theme.textColor};
  }
`;
