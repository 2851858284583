import React, { useEffect, useState } from "react";
import { Col, Row, Spin } from "antd";
import { ButtonComponent } from "../../components/common/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { ModalComponent } from "../../components/common/ModalComponent";
//@ts-ignore
import { ClockCircleOutlined } from "@ant-design/icons";
import { FaCopy as CopyIcon } from "react-icons/fa";
import {
  AwayOddSelection,
  ChatWeb,
  GameDetailsStyle,
  HomeOddSelection,
} from "../../components/common/StyleComponents";
import {
  formatNumberAmount,
  handleCopyClick,
  mapChallengeKey,
} from "../../utils/helperfuntions";
import Chat from "./components/Chat";
import {
  AddBet,
  AddBetContainer,
  ConfirmBet,
  ConfirmBetContent,
  Events,
  TextDesc,
  TextLabel,
} from "./Style";
import {
  FootballSelector,
  LiveGamesSelector,
  createChallengeAction,
  footballDetailsAction,
  FootballDetailsSelector,
  LeagueDetailsSelector,
  mode,
  useAppSelector,
  oppositeChallengeAction,
  acceptChallengeAction,
  GeneralDataSelector,
  ChallengesSelector,
  WalletSelector,
  getAllUsersAction,
} from "@michaelojo/vermo-redux";
import {
  StyledIcon,
  StyledTimeline,
  StyledTimelineItem,
} from "../../components/common/TimeLine";
import { IoIosFootball } from "react-icons/io";
import TextInput from "../../components/common/TextInput";
import { Container, Dropdown, Option } from "../../components/common/DropDown";
import { NotificationAlert } from "../../utils/NotificationAlert";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { Vermo } from "../../components/common/styled";

const handicapValue = [-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5];
const GameDetails = () => {
  const Footballtemplate = FootballSelector() as any;
  const [selectedOdd, setSelectedOdd] = React.useState("") as any;
  const [valueInput, setValueInput] = React.useState(0) as any;
  const [handicap, setHandicap] = useState<number>(0) as any;
  const [walletValue, setWalletValue] = React.useState() as any;
  const [loading, setLoadingState] = React.useState(false);
  const leagueDetails = LeagueDetailsSelector() as any;
  const [selectedClub, setSelectedClub] = React.useState("");
  const [openModal, setModal] = React.useState(false);
  const [selectedChallengeId, setSelectedChallengeId] = useState<string | null>(null); 
  const [isTextInputDisabled, setIsTextInputDisabled] = useState<boolean>(false);
  const [ isFriendSelected, setIsFriendSelected] = useState<string | null>(null);
  const [ isOpen, setIsOpen ] = useState<boolean>(false);
  const [ isCreateSuccess, setIsCreateSuccess ] = useState<string | null>(null);
  const [ isMsg, setIsMsg ] =  useState<string | null>(null);


  const dispatcher: ThunkDispatch<any, any, any> = useDispatch();

  // opposite challenge data
  const { userOppositeChallenges, userOppositeChallengesLoading, userAcceptChallengesLoading } = ChallengesSelector() as any;
  const { userWallets } = WalletSelector() as any
  const {  allUsers } = GeneralDataSelector() as any;

// getting all opposite challenges
  const oppositeChallenges = userOppositeChallenges?.oppositeChallenge || [];

  console.log("lst of opposite challenges:", oppositeChallenges)
 
  useEffect(() => {
    dispatcher(getAllUsersAction({}));
  },[dispatcher]);

  const allFriends = allUsers?.getUsers


 
// a function to toggle the opposite challenge 
  const handleRadioChange = (id: string, amount: number) => {
    setSelectedChallengeId((prevSelectedId) =>
      prevSelectedId === id ? null : id
    );
    setValueInput(amount);
    setIsTextInputDisabled(true);
  };

  // a function to get the opposite challenge amount
  const handleTextInputChange = (e: any) => {
    setValueInput(e.target.value);
    setIsTextInputDisabled(false);
  };

  const handleFriendSelectChange = (id: string) => {
    setIsFriendSelected(id)
  }


  // a function to open friend url modal on create challenge success
  const handleOpenFriendUrlModalSuccess = (url: string, msg: string) => {
    setIsOpen(!isOpen)
    setIsCreateSuccess(url)
    setIsMsg(msg)
  }

  const wallets = userWallets?.getUserWallets;

  const gameDetails = FootballDetailsSelector() as any;

  const navigate = useNavigate();
  const location = useLocation();
  const matchData = location.state;
  const liveGamesList = LiveGamesSelector() as any;


  const matchData2 = React.useMemo(() => {
    return liveGamesList.filter((data: any) => {
      const myData =
        data?.apiFootballFixtureId ===
        matchData?.match?.others?.apiFootballFixtureId;
      return myData;
    });
  }, [liveGamesList]);

  const lightmode = useAppSelector(mode);
  const count = 30000;
  const [counter, setCounter] = useState(count);
  const [createBetModal, setCreateBetModal] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setCounter((prev) => prev + 1);
    }, count);
    return () => (clearInterval(timer));
  }, []);

  useEffect(() => {
    const DOMContentLoaded_event = document.createEvent("Event");
    DOMContentLoaded_event.initEvent("DOMContentLoaded", true, true);
    window.document.dispatchEvent(DOMContentLoaded_event);
  }, [counter, lightmode]);

  useEffect(() => {
    if (matchData?.lockedChallenges) {
      console.log(' football id log--------------', matchData)
      dispatcher(
        footballDetailsAction({
          apiFootballFixtureId: matchData?.record?.gameFixtureId
        })
      )
        .unwrap()
        .then((originalPromiseResponse) => {
          if (
            !originalPromiseResponse?.getFixtureByApiFootballFixtureId?.status
          ) {
            NotificationAlert(
              "error",
              "Error occured",
              originalPromiseResponse?.getFixtureByApiFootballFixtureId?.msg
            );
          }
        })
        .catch((err) => {
          setLoadingState(false);
          NotificationAlert("error", "Error occured", err.message);
        });
    }
  }, []);
  // console.log("matchData", lockedGamesList);

  const handleSelected = (club: any, odd: any) => {
    if (selectedOdd && selectedClub) {
      setSelectedClub("");
      setSelectedOdd("");
    } else {
      setModal(true);
      setSelectedClub(club);
      setSelectedOdd(odd);
    }
  };
  const handleCreateModal = () => {
    setCreateBetModal(!createBetModal);
  };

  const handleCancelModal = () => {
    setSelectedClub("");
    setSelectedOdd("");
    setModal(false);
  };


  // check if there is opposite challenge
  const handleOppositeChange = async (selectedHandicap: number) => {
    setHandicap(selectedHandicap);

    const payload = {
      gameType: matchData?.match?.gameType,
      gameFixtureId: matchData?.match?.others?.id,
      betType: selectedOdd,
      team: selectedClub,
      handicap: selectedHandicap,
    };
    dispatcher(oppositeChallengeAction({ ...payload }));
    setSelectedChallengeId(null); 
    setIsTextInputDisabled(false);
    setValueInput(0); 
  };


  const handleWalletChange = (value: string) => {
    setWalletValue(value);
    handleOppositeChange(handicap);
  };


  // user accept challenge function
const handleAcceptChallenge = async () => {
  const payload = {
    gameBetId: selectedChallengeId,
    walletId: walletValue
  }
  dispatcher(acceptChallengeAction({...payload})).unwrap()
  .then((response) => {
    if(response?.acceptChallenge?.status === false) {
      NotificationAlert(
        "error",
        response?.acceptChallenge?.msg
      );
    } else if (response?.acceptChallenge?.status === true){
      NotificationAlert(
        "success",
        response?.acceptChallenge?.msg
      );
      handleCancelModal()
      handleCreateModal()
    }
  })
  .catch((error) => {
    NotificationAlert("error", error?.message);
  });
}


  const currentLeague = leagueDetails?.getLeagues?.filter((data: any) => {
    return (
      data?.apiFootballLeagueId ===
      gameDetails?.getFixtureByApiFootballFixtureId?.apiFootballLeagueId
    );
  });
  // console.log("current", currentLeague);
  const lockedChallengeEvent = React.useMemo(() => {
    return gameDetails?.getFixtureByApiFootballFixtureId?.events?.map(
      (event: any) => {
        return (
          <StyledTimeline mode="left">
            <StyledTimelineItem
              pending
              dot={
                <StyledIcon>
                  {event.type === "Goal" ? (
                    <IoIosFootball />
                  ) : (
                    <ClockCircleOutlined />
                  )}
                </StyledIcon>
              }
              label={`${event?.time}"`}
            >
              <p className="text">{event?.type}</p>
              <p className="text">{event?.detail}</p>
              <p className="text">
                {event?.team} F.C - {event?.player}
              </p>
            </StyledTimelineItem>
          </StyledTimeline>
        );
      }
    );
  }, [liveGamesList]);

  const odds = Footballtemplate?.betType?.map((odds: any, index: any) => (
    <div key={index} style={{ marginBottom: "20px" }}>
      <p className="oddsTitle">{odds?.name}</p>
      <div style={{ display: "flex", alignItems: "center" }}>
        <HomeOddSelection
          selected={
            selectedOdd === odds.name &&
            selectedClub === matchData?.match?.homeTeam
              ? true
              : false
          }
          onClick={() => handleSelected(matchData?.match?.homeTeam, odds.name)}
        >
          {matchData?.match?.homeTeam}
        </HomeOddSelection>
        <AwayOddSelection
          selected={
            selectedOdd === odds.name &&
            selectedClub === matchData?.match?.awayTeam
              ? true
              : false
          }
          onClick={() => handleSelected(matchData?.match?.awayTeam, odds.name)}
        >
          {matchData?.match?.awayTeam}
        </AwayOddSelection>
      </div>
    </div>
  ));


  const createChallenge = () => {
    setLoadingState(true);
    const payload = {
      gameType: matchData?.match?.gameType,
      gameFixtureId: matchData?.match?.others?.id,
      walletId: walletValue,
      amount: +valueInput,
      betType: selectedOdd,
      team: selectedClub,
      handicap: +handicap,
      friendId: isFriendSelected || null
    };
    // console.log(payload);
    dispatcher(createChallengeAction({ ...payload }))
      .unwrap()
      .then((res) => {
        setLoadingState(false);
        if (!res?.createChallenge?.status) {
          NotificationAlert(
            "error",
            "Error occured",
            res?.createChallenge?.msg
          );
        } else {
         
          if (isFriendSelected === null || ""){
            NotificationAlert(
              "success",
              "Successful",
              res?.createChallenge?.msg
            );
            handleCancelModal()
            setHandicap();
            setSelectedClub("");
            setSelectedOdd("");
            setValueInput("");
            navigate("/challenges");
          } else  {
            handleOpenFriendUrlModalSuccess(res?.createChallenge?.url, res?.createChallenge?.msg)
            setTimeout(() => {
              handleCancelModal()
              setHandicap();
              setSelectedClub("");
              setSelectedOdd("");
              setValueInput("");
              navigate("/challenges");
             }, 1 * 60 * 1000)
          }
        
        }
      })
      .catch((err) => {
        setLoadingState(false);
        NotificationAlert("error", "Error occured", err.message);
      });
  };
  // console.log(matchData?.match?.others?.apiFootballFixtureId);

  return (
    <div>
      <Row style={{ height: "100%", width: "100%" }} justify="center">
        <Col xs={{ span: 24 }} md={{ span: 20 }}>
          {location?.state?.showOdds === true && (
            <AddBetContainer
              style={{
                width: "fit-content",
                //   position: "fixed",

                //   bottom: 10,
                //   right: 40,
              }}
            >
              <ButtonComponent
                onClick={handleCreateModal}
                text={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <AddBet />
                    <span style={{ marginLeft: 5 }}>Create Bet</span>
                  </div>
                }
                style={{
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "25px",
                  height: 40,
                  margin: 0,
                  padding: "5px 15px",
                }}
              />
              <br />
            </AddBetContainer>
          )}
          <ModalComponent
            // styles={{ height: "500px" }}
            onCancel={handleCreateModal}
            visible={createBetModal}
            styles={{ padding: "20px"}}
          >
            <GameDetailsStyle>
              <div
                style={{
                  height: "500px",
                  overflowY: "scroll",
                  padding: "10px",
                }}
              >
                {odds}
              </div>
            </GameDetailsStyle>
            <ButtonComponent onClick={handleCreateModal} text="Cancel" style={{ width: "100%", marginTop: "15px", marginBottom: "15px"}} />
          </ModalComponent>

          {/* bet challenge details modal */}
          <ModalComponent
            onCancel={handleCancelModal}
            visible={openModal}
            width={1000}
          >
            <div className="FlexChallenge">
              {/* bet challenge details */}
              <div style={{ padding: "10px", textAlign: "center" }}>
                <ConfirmBet>Bet Challenge Details</ConfirmBet>
                <br />
                <ConfirmBetContent>
                  <p className="details">
                    You are about to create a bet challenge with the following
                    details:
                  </p>
                  <br />
                  <p className="detailsInfo">
                    Club selection: <span>{selectedClub}</span>
                  </p>
                  <p className="detailsInfo">
                    Bet Type: <span>{selectedOdd}</span>
                  </p>
                  <p className="detailsInfo">
                    Select Wallet:
                    <span>
                      <Container style={{ width: "50%", margin: "0 auto" }}>
                        <Dropdown
                          onChange={(e) => handleWalletChange(e.target.value)}
                        >
                          <Option disabled selected value="">
                            Select a wallet
                          </Option>
                          {wallets?.map((wallet: any) => (
                            <Option value={wallet?.id}>
                              {wallet?.currencyType?.kind}
                            </Option>
                          ))}
                        </Dropdown>
                      </Container>
                    </span>
                  </p>

                  <p className="detailsInfo">
                    Handicap(optional):
                    <span>
                      <Container style={{ width: "50%", margin: "0 auto" }}>
                        <Dropdown
                          onChange={(e) =>
                            handleOppositeChange(parseInt(e.target.value))
                          }
                        >
                          <Option disabled selected value="">
                            Select handicap value
                          </Option>
                          {handicapValue?.map((value: any) => (
                            <Option value={value}>{value}</Option>
                          ))}
                        </Dropdown>
                      </Container>
                    </span>
                  </p>

                  <p className="detailsInfo">
                    Bet Amount:
                    <span>
                      <TextInput
                        moreStyles={{
                          width: "70%",
                          alignItems: "center",
                          display: "flex",
                          margin: "0 auto",
                        }}
                        onChange={handleTextInputChange}
                        isDisabled={isTextInputDisabled}
                        placeholder="Enter amount"
                        value={valueInput}
                        inputType="number"
                        name="amount"
                      />
                    </span>
                  </p>
                  <p className="detailsInfo">
                    Winning:{" "}
                    <span> ${formatNumberAmount(+valueInput * 2)}</span>
                  </p>

                    {/*friend challenge  */}
                  {selectedChallengeId ? null : 
                    <p className="detailsInfo">
                      Friends Challenge (optional):
                    <span>
                      <Container style={{ width: "50%", margin: "0 auto" }}>
                        <Dropdown
                          onChange={(e) =>
                            handleFriendSelectChange(e.target.value)
                          }
                        >
                          <Option disabled selected value="">
                            Select Friends 
                          </Option>
                          {allFriends?.map((value: any) => (
                            <Option value={value?.id}>{value?.name}</Option>
                          ))}
                        </Dropdown>
                      </Container>
                    </span>
                    </p>
                  }

                </ConfirmBetContent>
                <br />
                    
                  

                <div style={{ width: "fit-content", margin: "0 auto" }}>
                  {selectedChallengeId ?
                    <div  style={{ display: "flex", justifyContent: "center",  width: "100%", gap: "5px"}}>
                           <ButtonComponent
                  onClick={() => handleAcceptChallenge()}
                    disabled={
                      !walletValue || !valueInput || valueInput <= 0
                        ? true
                        : false
                    }
                    text="Accept Challenge"
                    loading={userAcceptChallengesLoading}
                    style={{ width: "100%"}}
                  /> 

<ButtonComponent
                  onClick={handleCancelModal}
                    text="Cancel"
                    style={{ width: "100%"}}
                  /> 
                    </div>
                  
                  :
                  
                  <div style={{ display: "flex", justifyContent: "center",  width: "100%", gap: "5px"}}>
                         <ButtonComponent
                    onClick={() => createChallenge()}
                    disabled={
                      !walletValue || !valueInput || valueInput <= 0
                        ? true
                        : false
                    }
                    text="Create Challenge"
                    loading={loading}
                    style={{ width: "100%"}}
                  />

<ButtonComponent
                    onClick={handleCancelModal}
                    text="Cancel"
                    style={{ width: "100%"}}
                  />
                  </div>
                  
                  }
                </div>

            
              </div>

              {/* opposite challenge */}
              <div className="opposite_challenge detailsInfo">
                <p className="caption">
                <ConfirmBet>Opposite Challenges</ConfirmBet>
                </p>
               
                {userOppositeChallengesLoading ? (
                  <Spin size="large" />
                ) : oppositeChallenges?.length === 0 ? (
                  "No data available"
                ) : (
                  <table>
                    
                    <thead>
                      <tr>
                      <TextLabel>SN</TextLabel>
                      <TextLabel>USER NAME</TextLabel>
                      <TextLabel>EMAIL</TextLabel>
                      <TextLabel>HANDICAP</TextLabel>
                      <TextLabel>AMOUNT</TextLabel>
                      </tr>
                    </thead>
                    <tbody className="oppo_table_body">
                      {oppositeChallenges?.map((item: any) => (
                        <tr key={item?.id}>
                          <TextDesc><input type="radio" checked={selectedChallengeId === item?.id}
                            onChange={() => handleRadioChange(item?.id, item?.amount)} /></TextDesc>
                          <TextDesc>{item?.createdBy?.name?.slice(0, 15)}</TextDesc>
                          <TextDesc>{item?.createdBy?.betType?.slice(0, 8) + `***`}</TextDesc>
                          <TextDesc>{item?.optionsCreatedBy}</TextDesc>
                          <TextDesc>{item?.amount}</TextDesc>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </ModalComponent>

          {/* {val === true && ( */}
          <div>
            <div
              key="dark"
              id="wg-api-football-fixture"
              data-id={
                matchData?.record?.apiFootballFixtureId ||
                matchData?.record?.gameFixtureId || matchData?.match?.others?.apiFootballFixtureId
              }
              data-host="api-football-v1.p.rapidapi.com"
              data-refresh="0"
              // data-date="2022-02-11"
              data-key="56341d86demsh5faf1080e353c76p1dc542jsn49ff2dc52ee0"
              data-theme={"dark"}
              data-show-errors="true"
              className="api_football_loader"
            ></div>
            {/* )} */}
          </div>
          {/* )} */}
          {/* <ChatContainer>
      </ChatContainer> */}
        </Col>


        {/* friend modal */}
        {isOpen && (
          <ModalComponent
            visible={isOpen}
          >
            <Vermo.InnerContainer style={{ width: "100%"}}>  
              <div>
                <Vermo.FlexBetween style={{paddingBottom: "5px" }}>
                  <Vermo.TextTitle>{isMsg} successfully</Vermo.TextTitle>
                  <Vermo.TextTitle onClick={() => setIsOpen(false)} style={{cursor: "pointer", fontSize: "20px"}}>X</Vermo.TextTitle>
                </Vermo.FlexBetween>
                  <Vermo.TextSmall withPadding>Please, Kindly copy your friend invite address by clicking on the copy icon...</Vermo.TextSmall>
                <Vermo.TextSmall withPadding withAlign withFlex withBetween withCenter> {isCreateSuccess?.slice(0, 49)}  <CopyIcon size={25} onClick={() => handleCopyClick(isCreateSuccess)} className="copyIcon" /></Vermo.TextSmall>
              </div>
            
            </Vermo.InnerContainer>
        </ModalComponent>
        )}
      </Row>


    </div>
  );
};

export default GameDetails;
