import styled from "styled-components";
import { device } from "../../theme/breakPoints";
import { desktop, mobile } from "../../theme/fontSize";
import { Drawer, Input, Select } from "antd";
import { css } from "styled-components";


export const ProfileContainer = styled.div`
  background: ${(props) => props.theme.foreground};
  border-radius: 15px;
  padding: 20px;
  width: 50rem;
  @media (max-width: 768px) {
    width: 100%
  }
`;


export const Container = styled.div`
  background: ${(props) => props.theme.foreground};
  border-radius: 15px;
  padding: 20px;
  width: 100%;
`;

export const InnerContainer = styled.div<{ withMargin?: boolean }>`
  border: 1px solid ${(props) => props.theme.mutedColorPrimary};
  border-radius: 15px;
  padding: 20px;
  margin-bottom: ${(props) => (props.withMargin ? '20px' : '0')};
`;

export const FlexContainer = styled.div<{withMargin?: boolean}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${(props) => (props.withMargin ? '10px' : '0')};
  @media (max-width: 400px) {
      display: block;
      justify-content: center;
      align-items: center;
      text-align: center;
  }
`;

export const TextTitle = styled.p<{ withSize?: boolean, withPadding?: boolean}>`
  font-size: ${(props) => (props.withSize ? "20px" : "14px")};
  color: ${(props) => props.theme.textColor};
  padding-top: ${(props) => (props.withPadding ? "20px" : "0") };
  font-weight: bold;
  @media (max-width: 400px) {
    font-size: 14px;
  }
`;

export const TextSmall = styled.span<{ withPadding?: boolean, withAlign?: boolean, withFlex?: boolean, withBetween?: boolean, withCenter?: boolean, textSmall?: boolean}>`
  display: ${(props) => (props.withFlex ? "flex" : "") };
  justify-content: ${(props) => (props.withBetween ? "space-between" : "") };
  align-items: ${(props) => (props.withCenter ? "space-between" : "") };
  font-size: ${(props) => (props.textSmall ? "12px" : "16px") };
  color: ${(props) => props.theme.textColor};
  font-weight: 400;
  text-align: ${(props) => (props.withAlign ? "center" : "") };
  padding-top: ${(props) => (props.withPadding ? "20px" : "0") };
  @media (max-width: 400px) {
    font-size: 12px;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  @media (max-width: 400px) {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;

export const Img = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid ${(props) => props.theme.primaryColor};
`;

export const Flex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  @media (max-width: 400px) {
    display: block;
    justify-center: center;
    align-items: center;
    text-align: center;
  }
`;

export const FlexEnd = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: right;
  text-align: right;
  flex-direction: column;
`

export const FlexCenter = styled.div`
display: flex;
justify-content: flex-center;
align-items: center;
text-align: center;
flex-direction: column;
`

export const SelectBorder = styled.div`
width: 100%;
  border: 1px solid ${(props) => props.theme.mutedColorPrimary};
  border-radius: 8px;
  padding: 5px 10px;
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 10px;
  @media (max-width: 400px) {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`


export const FlexBetween = styled.div<{withMargin?: boolean}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${(props) => (props.withMargin ? '10px' : '0')};
`;



export const InputField = styled(Input)`
 &&& {
    outline: none !important;
    height: 100%;
    padding: 5px 10px;
    font-weight: 300;
    height: 40px;
    font-size: 14px;
    background: ${(props) => props.theme.foreground};
    color: ${(props) => props.theme.textColor};
    border-radius: 8px;

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    ::placeholder {
      color: ${(props) => props.theme.mutedColorSecondary};
    }
    .ant-input {
      background: ${(props) => props.theme.foreground};
      color: ${(props) => props.theme.textColor};
      font-size: ${mobile.small};
      border-radius: 8px;
      height: 40px;
      ::placeholder {
        color: ${(props) => props.theme.mutedColorSecondary};
      }
      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
        border-radius: inherit;
      }

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        -webkit-box-shadow: ${(props) => props.theme.foreground} !important;
      }
    }
    @media ${device.laptop} {
      font-size: ${desktop.small};
      .ant-input {
        font-size: ${desktop.small};
      }
    }
  }
`;


export const InputMessage = styled(Input.TextArea)`
 &&& {
    outline: none !important;
    height: 100%;
    padding: 5px 10px;
    font-weight: 300;
    height: 40px;
    font-size: 14px;
    background: ${(props) => props.theme.foreground};
    color: ${(props) => props.theme.textColor};
    border-radius: 8px;

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    ::placeholder {
      color: ${(props) => props.theme.mutedColorSecondary};
    }
    .ant-input {
      background: ${(props) => props.theme.foreground};
      color: ${(props) => props.theme.textColor};
      font-size: ${mobile.small};
      border-radius: 8px;
      height: 40px;
      ::placeholder {
        color: ${(props) => props.theme.mutedColorSecondary};
      }
      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
        border-radius: inherit;
      }

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        -webkit-box-shadow: ${(props) => props.theme.foreground} !important;
      }
    }
    @media ${device.laptop} {
      font-size: ${desktop.small};
      .ant-input {
        font-size: ${desktop.small};
      }
    }
  }
`;




export const SelectField = styled(Select)`
  width: 100%;
  border-radius: 8px ; 
  box-shadow: none !important;
  border: 1px solid ${(props) => props.theme.textColor};
  background: ${(props) => props.theme.foreground} !important;
  margin-bottom: 15px;
  color: ${(props) => props.theme.textColor};
  height: 40px;
  ::placeholder {
      color: ${(props) => props.theme.textColor};
      background: ${(props) => props.theme.foreground}
  }
  
`;

const SelectOption = styled(Select.Option)`
   background: transparent !important;
  color: ${(props) => props.theme.textColor};
`;


const StyledDrawer = styled(Drawer)`
  padding: none;
  text-align: center !important;

  .ant-drawer-body {
    background: ${(props) => props.theme.foreground};
    border: 0px solid white;
    box-shadow: ${(props) => props.theme.subtleShadow};
    border-radius: 0px;
    text-align: center !important;
    padding: 20px
  }
  .ant-drawer-content {
    border-radius: 0px;
  }

.ant-drawer-header {
  display: none;
  }
`;


export const Vermo = {
  InputMessage,
  StyledDrawer,
  InputField,
    SelectField,
    SelectOption,
    Container,
    FlexContainer,
    InnerContainer,
    ProfileContainer,
    FlexEnd,
    Img,
    TextTitle,
    TextSmall,
    TextWrapper,
    Flex,
    FlexCenter,
    SelectBorder,
    Grid,
    FlexBetween
}