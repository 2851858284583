


import { ThunkDispatch } from "@reduxjs/toolkit";
import { addCommentAction, addFollowerAction, addLikeAction, createFriendChatRoom } from "@michaelojo/vermo-redux";
import { NotificationAlert } from "../NotificationAlert";
import { getUserAction } from "@michaelojo/vermo-redux"



// user comment function
export const handleUserComment = async (
  dispatch: ThunkDispatch<any, any, any>,
  values: any,
  banterMatchId: string | null,
  resetForm: () => void
) => {
  const payload = {
    commentableId: banterMatchId,
    commentableType: values.commentableType,
    comment: values.comment,
  }

  const res = await dispatch(addCommentAction(payload)).unwrap()
    try {
     
      if(res?.addComment?.status === true) {
        NotificationAlert("success", res?.addComment?.msg)
        resetForm()
      }
    } catch (error: any) {
      
      NotificationAlert("error", error?.addComment?.msg);
    }
};



export const handleFriendComment = async (
  dispatch: ThunkDispatch<any, any, any>,
  values: any,
  resetForm: () => void
) => {
  const payload = {
    chatRoomId: values.chatRoomId,
    comment: values.comment,
  }

  const res = await dispatch(createFriendChatRoom(payload)).unwrap()
    try {
      console.log("Add Chat", res);
      if(res?.createChat?.status === true) {
        NotificationAlert("success", res?.addComment?.msg)
        resetForm()
      }
    } catch (error: any) {
      console.log("Add Chat Error", error);
      NotificationAlert("error", error?.addComment?.msg);
    }
};



// user like function
export const handleUserLike = async (
  dispatch: ThunkDispatch<any, any, any>,
  id: string,
  likedMatches: string[],
  values: any,
  setLikedMatches: React.Dispatch<React.SetStateAction<string[]>>
) => {
  const payload = {
    likeableId: id,
    likeableType: values.likeableType,
  };
  return dispatch(addLikeAction(payload))
    .unwrap()
    .then((res: any) => {
      if (res?.addLike?.status === true) {
        NotificationAlert("success", res?.addLike?.msg);
        const updatedLikedMatches = [...likedMatches, id];
        setLikedMatches(updatedLikedMatches);
        localStorage.setItem("@likedMatches", JSON.stringify(updatedLikedMatches));
      } else {
        NotificationAlert("error", res?.addLike?.msg);
      }
    })
    .catch((error: any) => {
      NotificationAlert("error", error?.addComment?.msg);
    });
};



export const handleUserFollower = async (
  dispatch: ThunkDispatch<any, any, any>,
  id: string,
  setLoadingStates: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>
) => {
  setLoadingStates((prevState) => ({
    ...prevState,
    [id]: true,
  }));

  const payload = { userId: id };

  try {
    const res = await dispatch(addFollowerAction(payload)).unwrap();
    if (res?.followUser?.status === true) {
      NotificationAlert("success", res?.followUser?.msg);
      setTimeout(() => {
        window.location.reload();
    }, 100);
      dispatch(getUserAction({}));
    } else {
      NotificationAlert("error", res?.followUser?.msg);
    }
  } catch (error: any) {
    NotificationAlert("error", error?.followUser?.msg || "Error occurred while following the user.");
  } finally {
    setLoadingStates((prevState) => ({
      ...prevState,
      [id]: false,
    }));
  }
};




// banter view function
export const handleBanterView = (
    id: string,
    banterMatchId: string | null,
    setBanterMatchId: React.Dispatch<React.SetStateAction<string | null>>
  ) => {
    setBanterMatchId(banterMatchId === id ? null : id);
  };