import React from "react";
import { ModalComponent } from "../common/Modal";
import moment from "moment";
import { formatNumberAmount, handleCopyClick } from "../../utils/helperfuntions";
import { ConfirmBetContent } from "../../Views/GameDetails/Style";
import { Vermo } from "../common/styled";
import { Challenger } from "../common/StyleComponents";
import { IoCopy as CopyIcon } from "react-icons/io5";


interface ChallengeDetailsModalProps {
  visible: boolean;
  onCancel: () => void;
  challengeDetails: any;
  selectedChallengeId: string | null;
}

const ChallengeDetailsModal: React.FC<ChallengeDetailsModalProps> = ({ visible, onCancel, challengeDetails, selectedChallengeId }) => {
  return (
    <ModalComponent visible={visible} onCancel={onCancel}>
      <div style={{ padding: "10px  40px"}}>
        <Vermo.TextTitle style={{fontSize: "30px"}}>Challenge Details</Vermo.TextTitle>
        {challengeDetails ? (
          <ConfirmBetContent style={{ paddingTop: "20px"}}>
            <p className="detailsInfo">Date: <span>{moment(challengeDetails.createdAt).format("DD.MM.YYYY hh:mm a")}</span></p>
            <p className="detailsInfo">Game Type: <span>{challengeDetails.gameType}</span></p>
            <p className="detailsInfo">Created for Team: <span>{challengeDetails.createdByTeam}</span></p>
            <p className="detailsInfo">Accepted for Team: <span>{challengeDetails.acceptedByTeam}</span></p>
            <p className="detailsInfo">Winning Amount: <span> $ {formatNumberAmount(challengeDetails.amount * 2)}</span></p>
            <p className="detailsInfo">Bet Type: <span>{challengeDetails.betType}</span></p>

            <Vermo.FlexBetween style={{ paddingTop: "20px"}}>
               <Vermo.TextWrapper>
                  <Vermo.TextSmall>Challenge Created By:</Vermo.TextSmall>
                  <Vermo.TextSmall>{challengeDetails?.createdBy?.name}</Vermo.TextSmall>
               </Vermo.TextWrapper>

               <Vermo.TextWrapper>
                  <Vermo.TextSmall>Challenge Accepted By:</Vermo.TextSmall>
                  <Vermo.TextSmall>{challengeDetails?.acceptedBy?.name ?? "N/A"}</Vermo.TextSmall>
               </Vermo.TextWrapper>
            </Vermo.FlexBetween>

            <Vermo.FlexCenter style={{ paddingTop: "20px" }}>
          <Challenger style={{display: "flex", justifyContent: "center", alignItems: "center", gap: "8px"}}>
                <Vermo.TextSmall>{`${window.location.hostname?.slice(0, 9) ?? "N/A"}`}</Vermo.TextSmall>
            <Vermo.TextSmall onClick={() => handleCopyClick(window.location.hostname + `/friend-challenge/` + selectedChallengeId)}><CopyIcon /> </Vermo.TextSmall>
            </Challenger>
        </Vermo.FlexCenter>
          </ConfirmBetContent>
        ) : (
          <p className="detailsInfo">No details available.</p>
        )}
      </div>
    </ModalComponent>
  );
};

export default ChallengeDetailsModal;
