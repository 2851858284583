import React from "react";
import { Button as AntButton } from "antd";
import styled from "styled-components";
import { device } from "../../theme/breakPoints";

interface ButtonInterface {
  width?: string;
  htmlType?: string;
  disabled?: boolean;
  text: string | any;
  onClick?: () => void;
  style?: React.CSSProperties;
  loading?: boolean;
  colorprop?: boolean;
}

export const ButtonComponent: React.FC<ButtonInterface> = ({
  colorprop,
  width,
  htmlType,
  disabled,
  text,
  onClick,
  style,
  loading,
}) => {
  return (
    <Container>
      <StyledButton
        colorprop={colorprop}
        style={{ ...style }}
        disabled={disabled}
        width={width}
        size="large"
        onClick={onClick}
        htmlType={htmlType}
        loading={loading}
      >
        {text}
      </StyledButton>
    </Container>
  );
};
interface ButtonProp {
  width: string;
  colorprop: boolean;
}
const StyledButton: any = styled(AntButton)<ButtonProp>`
  border: ${(props) => props.theme.primaryColor};
  cursor: pointer;
  background: ${(props) =>
    props.colorprop
      ? props.theme.mutedColorTertiary
      : props.theme.primaryColor};
  color: ${(props) => (props.colorprop ? "white" : "white")};
  font-weight: 500;
  box-shadow: ${(props) => props.theme.authShadow};
  border-radius: 4px;

  width: ${(props) => props.width || "100%"};
  transition: all 0.5s ease;
  :active,
  :focus,
  :hover {
    box-shadow: none;
    transition: all 0.5s ease;
    background: ${(props) =>
      props.colorprop
        ? props.theme.mutedColorTertiary
        : props.theme.primaryColor};
    color: white;
  }
  :disabled {
    background-color: ${(props) => "#e8e8e8"};
  }
`;

const Container = styled.div`
  text-align: center;
  ${"" /* margin-top: -20px; */}
  @media ${device.laptop} {
    width: 100%;
    margin: 0;
    height: 2.5vw;
    text-align: center;
  }
`;
