import React from "react";
import { ModalComponent } from "../common/ModalComponent";
interface ModalInterface {
  onCancel: () => void;
  visible: boolean;
  actionType: any;
}
const UserModal = ({ actionType, onCancel, visible }: ModalInterface) => {
  return (
    <>
      <ModalComponent visible={visible} onCancel={onCancel}></ModalComponent>
    </>
  );
};

export default UserModal;
