import { useEffect, useState } from "react";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import {
  ChallengesSelector,
  GeneralDataSelector,
  WalletSelector,
  getAllUsersAction,
  getUsersWalletAction,
  getSingleChallengeDetailsAction,
  acceptChallengeAction,
} from "@michaelojo/vermo-redux";
import { Skeleton } from "antd";
import { Vermo } from "../../components/common/styled";
import { useNavigate, useParams } from "react-router-dom";
import { Dropdown, Option } from "../../components/common/DropDown";
import { ButtonComponent } from "../../components/common/Button";
import { PageTitle } from "../../components/common/StyleComponents";
import { formatDate, formatTime } from "../../utils/date_time_formatter";
import { NotificationAlert } from "../../utils/NotificationAlert";

const FriendChallenge = () => {
  const { id } = useParams();
  const dispatch: ThunkDispatch<any, any, any> = useDispatch();
  const [walletValue, setWalletValue] = useState<string | null>(null);
  const { userDetails, loading } = GeneralDataSelector() as any;
  const { userWallets } = WalletSelector() as any;
  const { getSingleChallengeDetails, userAcceptChallengesLoading } = ChallengesSelector() as any;
  const navigate = useNavigate();

  // extracting user wallet information from wallet reducer
  const wallet = userWallets?.getUserWallets || [];
  const userDetail = userDetails.me;
  const challenges = getSingleChallengeDetails?.singleChallenge;


  const getChallengePayload = {
    gameBetId: id?.toString(),
    friendId: userDetail?.id,
  };

  useEffect(() => {
    dispatch(getAllUsersAction({}));
    dispatch(getUsersWalletAction({}));
    if (id && userDetail ?.id) {
        dispatch(getSingleChallengeDetailsAction(getChallengePayload));
      }
  }, [dispatch, id, userDetail]);

  // user accept challenge function
  const handleAcceptChallenge = async () => {
    if (!walletValue || !id) return;

    const payload = {
      gameBetId: id,
      walletId: walletValue,
    };

    try {
      const res = await dispatch(acceptChallengeAction(payload)).unwrap();
      const alertType = res.acceptChallenge.status ? "success" : "error";
      NotificationAlert(alertType, res.acceptChallenge.msg);
      navigate("/challenge");
    } catch (error: any) {
      NotificationAlert("error", error.message);
    }
  };

  return (
    <>
      <PageTitle>
        <h1>Friend Challenges</h1>
        <h2>Accept Challenges from other users on Vermo</h2>
      </PageTitle>
      <br />
      <Vermo.Grid>
        <Vermo.Container>
          <Vermo.InnerContainer>
            <div style={{ marginBottom: "30px" }}>
              <Vermo.FlexCenter>
                <Vermo.TextTitle withSize>Challenge Details</Vermo.TextTitle>
              </Vermo.FlexCenter>
            </div>

            <Vermo.FlexBetween withMargin>
              <Vermo.TextSmall textSmall>Challenger Name</Vermo.TextSmall>
              <Vermo.TextTitle>
                {loading ? (
                  <Skeleton.Input active={true} size="large" />
                ) : (
                  challenges?.createdBy?.name ?? "N/A"
                )}
              </Vermo.TextTitle>
            </Vermo.FlexBetween>

            <Vermo.FlexBetween withMargin>
              <Vermo.TextSmall textSmall>Date and Time</Vermo.TextSmall>
              <Vermo.TextTitle>
                {loading ? (
                  <Skeleton.Input active={true} size="large" />
                ) : (
                  <>
                    {formatDate(challenges?.createdAt) ?? "N/A"} |{" "}
                    {formatTime(challenges?.createdAt) ?? "N/A"}
                  </>
                )}
              </Vermo.TextTitle>
            </Vermo.FlexBetween>

            <Vermo.FlexBetween withMargin>
              <Vermo.TextSmall textSmall>Bet Type</Vermo.TextSmall>
              <Vermo.TextTitle>
                {loading ? (
                  <Skeleton.Input active={true} size="large" />
                ) : (
                  challenges?.betType ?? "N/A"
                )}
              </Vermo.TextTitle>
            </Vermo.FlexBetween>

            <Vermo.FlexBetween withMargin>
              <Vermo.TextSmall textSmall>Challenger Selection</Vermo.TextSmall>
              <Vermo.TextTitle>
                {loading ? (
                  <Skeleton.Input active={true} size="large" />
                ) : (
                  challenges?.createdByTeam?.slice(0, 18) ?? "N/A" + "..."
                )}
              </Vermo.TextTitle>
            </Vermo.FlexBetween>

            <Vermo.FlexBetween withMargin>
              <Vermo.TextSmall textSmall>My Selection</Vermo.TextSmall>
              <Vermo.TextTitle>
                {loading ? (
                  <Skeleton.Input active={true} size="large" />
                ) : (
                  challenges?.acceptedByTeam ?? "N/A"
                )}
              </Vermo.TextTitle>
            </Vermo.FlexBetween>

            <Vermo.FlexBetween withMargin>
              <Vermo.TextSmall textSmall>Stake Amount</Vermo.TextSmall>
              <Vermo.TextTitle>
                {loading ? (
                  <Skeleton.Input active={true} size="large" />
                ) : (
                  challenges?.amount ?? "N/A"
                )}
              </Vermo.TextTitle>
            </Vermo.FlexBetween>

            <div style={{ marginTop: "20px" }}>
              <Vermo.FlexCenter>
                <Vermo.TextSmall textSmall>
                  Select Withdraw Wallet
                </Vermo.TextSmall>
                <Vermo.SelectBorder>
                  <Dropdown onChange={(e) => setWalletValue(e.target.value)}>
                    <Option disabled selected value="">
                      Select a wallet
                    </Option>
                    {wallet?.map((wallet: any) => (
                      <Option value={wallet?.id}>
                        {wallet?.currencyType?.kind}
                      </Option>
                    ))}
                  </Dropdown>
                </Vermo.SelectBorder>
              </Vermo.FlexCenter>
            </div>

            <div style={{ marginTop: "30px" }}>
              <ButtonComponent
                onClick={() => handleAcceptChallenge()}
                disabled={!walletValue || challenges?.amount === undefined ? true : false}
                text="Accept Challenge"
                loading={userAcceptChallengesLoading}
              />
            </div>
          </Vermo.InnerContainer>
        </Vermo.Container>
      </Vermo.Grid>
    </>
  );
};

export default FriendChallenge;
