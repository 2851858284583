import React from "react";
import styled from "styled-components";
import { Table as AntTable } from "antd";
import { desktop, mobile } from "../../theme/fontSize";
import { device } from "../../theme/breakPoints";

const TableComponent = (props: any) => {
  return (
    <StyledTable
      size="large"
      {...props}
      dataSource={props.dataSource}
      columns={props.columns}
      onRow={(record: any) => {
        return {
          onClick: (e) => {
            props.rowClick(record);
          },
        };
      }}
    />
  );
};
export default TableComponent;
export const StyledTable = styled(AntTable)`
  display: block;
  color: ${(props) => props.theme.textColor};
  width: 100%;
  text-align: center;
  border: ${(props) => `1px solid ${props.theme.mutedColorSecondary}`};

  border-top: none;
  border-left: none;
  border-bottom: none;
  border-right: none;
  background: ${(props) => props.theme.background};

  .ant-table {
    /* border: none; */
    background: ${(props) => props.theme.background};
    color: ${(props) => props.theme.textColor};
    font-weight: 500;
    box-shadow: ${(props) => props.theme.subtleShadow};
    font-size: ${mobile.small};
    :hover {
      background: ${(props) => props.theme.background};
    }
    tr {
      border: 1px solid red;
    }
  }

  table {
    border-spacing: 0 15px !important;
    border-radius: 10px;
  }

  .ant-table-tbody > tr:nth-child(1n) td {
    background-color: ${(props) => props.theme.foreground};
    color: ${(props) => props.theme.textColor};
    border: none;
    font-weight: 400;
    cursor: pointer;
    margin: 5px 0 !important;
  }
  .ant-table-tbody > tr:nth-child(2n) td {
    background-color: ${(props) => props.theme.foreground};
    color: ${(props) => props.theme.textColor};
    font-weight: 400;
    cursor: pointer;
    margin: 5px 0 !important;
  }
  .ant-table-thead > tr > th {
    background-color: ${(props) => props.theme.background};
    border: none;
    font-weight: 600;
    font-family: Montserrat;
    font-size: ${mobile.small};
    color: ${(props) => props.theme.textColor};
  }
  .ant-table-tbody > tr.ant-table-row:hover {
    transform: scale(1.02);
    transition-property: all;
    transition-duration: 600;
    border: 1px solid red;
  }
  @media ${device.laptop} {
    display: block;
    .ant-table {
      font-size: ${desktop.small};
    }
    .ant-table-tbody > tr:nth-child(1n) td {
      background-color: ${(props) => props.theme.foreground};
      color: ${(props) => props.theme.textColor};
      font-weight: 300;
      cursor: pointer;
    }
    .ant-table-tbody > tr:nth-child(2n) td {
      background: ${(props) => props.theme.foreground};
      font-weight: 300;
      cursor: pointer;
    }

    .ant-table-thead > tr > th {
      background-color: ${(props) => props.theme.background};
      border: none;
      border-bottom: ${(props) =>
        `1px solid ${props.theme.mutedColorSecondary}`};
      font-weight: 600;
      font-size: ${desktop.small};
      color: ${(props) => props.theme.textColor};
    }

    .ant-table-tbody > tr {
      border-radius: 10px;
      border: ${(props) => `1px solid ${props.theme.mutedColorSecondary}`};
      background-color: ${(props) => props.theme.foreground};
    }
  }
`;
