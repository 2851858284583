export enum WalletScreen {
  AMOUNT = "AMOUNT",
  CREATE_PAYMENT_METHOD = "CREATE_PAYMENT_METHOD",
  SELECT_PAYMENT_METHOD = "SELECT_PAYMENT_METHOD",
  CONFIRM_PAYMENT = "CONFIRM_PAYMENT",
  ADD_NEW_PAYMENT = "ADD_NEW_PAYMENT",
  NGNCREATEPAYOUT = "nigerian_banktransfer",
  SUCCESSFUL_PAYMENT = "SUCCESSFUL_PAYMENT",
}
export interface PlanProps {
  type: String;
  amount: number;
  shortText: String;
}
