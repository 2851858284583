import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { PageTitle } from "../../components/common/StyleComponents";
import TextInput from "../../components/common/TextInput";
import UserModal from "../../components/Modals/UserModal";
import {
  getAllUsersAction,
  GeneralDataSelector,
} from "@michaelojo/vermo-redux";
import { IoChatbubbleEllipsesSharp as ChatIcon } from "react-icons/io5";
import { StyledTable } from "../../components/common/TableComponent";
import { handleUserFollower } from "../../utils/actions/actions";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { Vermo } from "../../components/common/styled";
import { ButtonComponent } from "../../components/common/Button";
import FriendChatModal from "../../components/others/SelectSports/FriendChat";

const User = () => {
  const dispatch: ThunkDispatch<any, any, any> = useDispatch()
  const [name, setName] = React.useState("");
  const [friendChatId, setFriendChatId] = useState<string | null>(null);
  const [ friendChatOpen, setFriendChatOpen ] = useState<boolean>(false)
  const [showModal, setShowModal] = React.useState(false);
  const { allUsersLoading, allUsers } = GeneralDataSelector() as any
  const [loadingStates, setLoadingStates] = useState<{ [key: string]: boolean }>({});


  const handleOpenFriendChat = (id: string) => {
    setFriendChatId(id);
    setFriendChatOpen(true);
  }

  const handleCloseFriendChat = () => {
    setFriendChatId(null);
    setFriendChatOpen(false);
  }
  useEffect(() => {
    dispatch(getAllUsersAction({}));

  },[dispatch]);

  const allFriends = allUsers?.getUsers

  const chatFriend = allFriends?.find((friend: any) => friend?.id === friendChatId);

  const handleNameChange = (value: any) => {
    setName(value);
  };


  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      render: (text: any) => <div>{text === "F" ? "Female" : "Male"}</div>,
    },
    {
      title: "Points",
      dataIndex: "points",
      key: "genpointsder",
      render: (text: any) => <div>0 points</div>,
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Status",
      dataIndex: "id",
      render: (_: any, record: any) => (
         <>
             <ButtonComponent
             style={{ width: "150px"}}
              loading={loadingStates[record.id] || false}
              text={record?.followed ? "Unfollowed" : "Follow"}
              onClick={() => {
              handleUserFollower(dispatch, record?.id, setLoadingStates)
            }}
            />
         </>
      )
    },
    {
      title: "",
      dataIndex: "id",
      render: (_: any, record: any) => (
        <>
          <Vermo.Flex style={{ position: "relative" }}>
              <Vermo.TextSmall onClick={() => handleOpenFriendChat(record?.id)}>
                <ChatIcon size={20} />  
              </Vermo.TextSmall>
            </Vermo.Flex>
        </>
      ),
    }
    
  ];
  return (
    <>
      <UserModal
        visible={showModal}
        onCancel={() => setShowModal(false)}
        actionType={"add"}
      />
      <PageTitle>
        <h1>Friends</h1>
        <h2>Make friends by following other users on Vermo</h2>
      </PageTitle>
      <br />
      <Row>
        <Col xs={24} md={12}>
          <TextInput
            value={name}
            inputType="text"
            name="name"
            label={"Filter users list"}
            moreStyles={{ borderRadius: "20px" }}
            placeholder="Type a user information you would like to search..."
            onChange={(e) => handleNameChange(e.target.value)}
          />
        </Col>
      </Row>
      <br />
      <PageTitle>
        <h2>
          Filter and follow trending users based on winning streaks or other
          criteria.
        </h2>
      </PageTitle>
      <StyledTable
        scroll={{ x: 600 }}
        columns={columns}
        dataSource={allFriends}
        loading={allUsersLoading}
      />
      {friendChatOpen && (
        <Vermo.StyledDrawer
          open={friendChatOpen}
          closeIcon={null}
          title={null}
          width={410}
        >
          <FriendChatModal friendId={chatFriend.id} SetFriendId={() => handleCloseFriendChat()} currentMatch={chatFriend} />
        </Vermo.StyledDrawer>
      )}
    </>
  );
};

export default User;
