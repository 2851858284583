import { Button, Result } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "../Successful/style";

const ErrorPayment = () => {
  const navigate = useNavigate();

  const goToWallet = () => {
    navigate("/wallet");
  };
  return (
    <Container>
      <Result
        status="error"
        title="Payment Error"
        subTitle="Please make payment again, an error occured."
        extra={[
          <Button onClick={goToWallet} type="primary" key="console">
            Retry Payment
          </Button>,
        ]}
      ></Result>
    </Container>
  );
};

export default ErrorPayment;
