import React from "react";
import { ButtonComponent } from "../../components/common/Button";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import {
  AuthSubtitle,
  AuthTitle,
  AuthWrapper,
} from "../../components/common/StyleComponents";
import TextInput from "../../components/common/TextInput";
import Auth from "../../components/layouts/Auth";
import { validationSchema } from "../../utils/validationSchema";
import { Col, Row } from "antd";
import DropDown from "../../components/common/DropDown";
import DateInput from "../../components/common/DateInput";
import PhoneNumberInput from "../../components/common/PhoneNumberInput";
import {
  registerAction,
  RegisterSelector,
  setRegistrationInfo,
} from "@michaelojo/vermo-redux";
import { createUserVariables } from "@michaelojo/vermo-redux/lib/services/__generated__/createUser";

import { NotificationAlert } from "../../utils/NotificationAlert";
import VerifyModal from "../../components/Modals/VerifyModal";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

interface Values {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  gender?: string;
  dob?: any;
  phoneNumber?: any;
  country?: string;
}

const Register: React.FC = () => {
  // const dispatcher = useAppDispatch();
  const dispatcher: ThunkDispatch<any, any, any> = useDispatch()
  const navigate = useNavigate();
  const caller = RegisterSelector() as any;
  // const userEmail = "richardadesina26@gmail.com";
  const [showModal, setShowModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);


  const registerUser = async (values: Values) => {
    setLoading(true);
    const payload: createUserVariables = {
      createUser: {
        name: `${values.firstName} ${values.lastName}`,
        dob: values.dob,
        email: values.email,
        gender: String(values.gender)[0],
        mobile: values.phoneNumber,
        password: values.password,
        country: values.country,
      },
    };

    try {
      dispatcher(setRegistrationInfo(payload));
      const res = await dispatcher(registerAction(payload)).unwrap();
      setLoading(false);
      NotificationAlert("success", res?.createUser?.msg);
      setShowModal(true);
    } catch (error: any) {
      setLoading(false);
      const graphQLErrors = error.graphQLErrors;

// Loop through each error object
      let message = '';
      graphQLErrors.forEach((err: any) => {
          const originalError = err.extensions.originalError;
          if (originalError && Array.isArray(originalError.message)) {
              // Loop through the message array within the originalError object
              originalError.message.forEach((errorMessage: any )=> {
                  message = message + "  \n" + errorMessage;
              });
          }
      });
      if(message.length > 0) { 
        NotificationAlert("error", message);
      }
      else {

        NotificationAlert("error", error?.message);
      }
    }
  };


  return (
    <Auth>
      <VerifyModal
        email={caller.registerationInfo?.email}
        visible={showModal}
        onCancel={() => {
          setShowModal(false);
          navigate("/login");
        }}
      />
      <AuthWrapper>
        <AuthTitle>Sign up on Vermo with your mail</AuthTitle>
        <AuthSubtitle>
          Already have an Account?
          <span onClick={() => navigate("/login")}> Sign in</span>
        </AuthSubtitle>
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            gender: "",
            dob: "",
            phoneNumber: "",
            country: "",
          }}
          onSubmit={(values: Values) => {
            registerUser(values);
          }}
          validationSchema={yup.object().shape(validationSchema.signup)}
        >
          {({ values, handleChange, setFieldValue, errors, handleSubmit }) => (
            <>
              <TextInput
                required
                inputType="text"
                value={values.firstName}
                name="firstName"
                placeholder="Enter your First Name"
                onChange={handleChange}
                error={errors.firstName}
                label="First Name"
              />
              <TextInput
                required
                inputType="text"
                name="lastName"
                onChange={handleChange}
                value={values.lastName}
                placeholder="Enter your Last Name"
                error={errors.lastName}
                label="Last Name"
              />

              <TextInput
                required
                inputType="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                placeholder="Enter your email"
                label="Email"
                error={errors.email}
              />
              <PhoneNumberInput
                label="Phone Number"
                error={errors?.phoneNumber as any}
                field="phoneNumber"
                onChange={setFieldValue}
                name={"phoneNumber"}
                placeholder="Enter your mobile number"
              />
              <TextInput
                required
                inputType="password"
                name="password"
                placeholder="Enter your Password"
                value={values.password}
                label="Password"
                onChange={handleChange}
                error={errors.password}
              />
               <Row >
                <Col lg={24} xs={24}>
                  <DropDown
                    onChange={setFieldValue}
                    name="country"
                    label="Country"
                    field={"country"}
                    placeholder="Select your Country"
                    values={["NGN", "USA"]}
                    error={errors.country}
                  />
                </Col>
                </Row>
              <Row justify={"space-between"}>
                <Col lg={11} xs={24}>
                  <DropDown
                    onChange={setFieldValue}
                    name="gender"
                    label="Gender"
                    field={"gender"}
                    placeholder="Select your Gender"
                    values={["Male", "Female"]}
                    error={errors.gender}
                  />
                </Col>
                <Col lg={11} xs={24}>
                  <DateInput
                    error={errors.dob}
                    label="Date of Birth"
                    changeValue={setFieldValue}
                    name="dob"
                    field="dob"
                  />
                </Col>
              </Row>
              <ButtonComponent
                loading={loading}
                onClick={handleSubmit}
                text={"Create Account"}
                style={{ marginTop: "10px" }}
              />
            </>
          )}
        </Formik>
      </AuthWrapper>
    </Auth>
  );
};

export default Register;
