import { useState } from "react";
import { Avatar, Tooltip } from "antd";
import styled from "styled-components";
import { device } from "../../../theme/breakPoints";
import { desktop } from "../../../theme/fontSize";
import { useNavigate } from "react-router-dom";
import { Vermo } from "../../common/styled";
import { MdFavorite as LikeIcon } from "react-icons/md";
import { IoChatbubbleEllipsesSharp as ChatIcon } from "react-icons/io5";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { GeneralDataSelector  } from "@michaelojo/vermo-redux";
import { handleUserLike } from "../../../utils/actions/actions";
import useLocalStorageState from "../../../hoc/storage";
import BanterModal from "./BanterModal";


interface iFootball {
  matchDets: any;
  data?: any;
}

interface ChatBoxProps {
  isCurrentUser: boolean;
}

const PairSport = ({ matchDets, data }: iFootball) => {
  const navigate = useNavigate();
  const dispatch: ThunkDispatch<any, any, any> = useDispatch();
  const [banterMatchId, setBanterMatchId] = useState<string | null>(null);
  const [ banterOpen, setBanterOpen ] = useState<boolean>(false)
  const { userDetails } = GeneralDataSelector() as any;
  const [likedMatches, setLikedMatches] = useLocalStorageState<string[]>("@likedMatches", []);


  const handleOpenBanter = (id: string) => {
    setBanterMatchId(id);
    setBanterOpen(true);
  }

  const handleCloseBanter = () => {
    setBanterMatchId(null);
    setBanterOpen(false);
  }


  const isMatchLiked = (id: string) => likedMatches.includes(id);

  // Current match
  const currentMatch = matchDets?.find((match: any) => match?.others?.id === banterMatchId);

  const dualSports = matchDets?.map((match: any) => {

    return (
      <Container
        key={match?.others?.id}
        active={match?.liveStatus ? true : false}
      >
        <div className="container">
          <div className="minWrapper durationWrapper">
            <div>
              <p className="time">{match?.time}</p>
              <p className="date">{match?.date}</p>
            </div>
            {match.liveStatus && (
              <div className="live">LIVE {match?.gameTime}"</div>
            )}
          </div>
          <div
            onClick={() =>
              navigate("/details", {
                state: {
                  showChat: false,
                  showOdds: match?.liveStatus === true ? false : true,
                  match,
                  data,
                },
              })
            }
            className="maxWrapper"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="percentWrapper">
              <img
                className="leagueimg"
                src={match?.homeTeamLogo}
                alt="home"
              />
              <p className="teamName">{match?.homeTeam}</p>
            </div>
            <div className="scoreContent">
              {match.liveStatus === true
                ? `${match?.homeScore ?? 0}:${match?.awayScore ?? 0}`
                : "VS"}
            </div>
            <div className="percentWrapper">
              <p className="teamName"> {match?.awayTeam}</p>
              <img
                className="leagueimg"
                src={match?.awayTeamLogo}
                alt="away"
              />
            </div>
          </div>

          <div
            className="showMobile minWrapper"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Avatar.Group size={{ xs: 24, sm: 32, md: 40 }} maxCount={2}>
              {match?.inplay?.players?.map((pic: any, id: any) => (
                <Tooltip title="user" placement="top" key={id}>
                  <Avatar key={id} alt={`user ${id + 1}`} src={pic} />
                </Tooltip>
              ))}
            </Avatar.Group>
          </div>
          <Vermo.Flex>
            <LikeIcon
              color={isMatchLiked(match?.others?.id) ? "red" : "#603be8"}
              size={20}
              onClick={() =>
                handleUserLike(dispatch, match?.others?.id, likedMatches, { likeableType: "FootballFixtures" }, setLikedMatches)
              }
            />
            <Vermo.Flex style={{ position: "relative" }}>
              <Vermo.TextSmall onClick={() => handleOpenBanter(match?.others?.id)}> <ChatIcon size={20} />  </Vermo.TextSmall>
              <Vermo.TextSmall style={{ position: "absolute", top: "-20px", right: "0px" }}>
                {match?.others?.commentCount === 0 ? null : match?.others?.commentCount}
              </Vermo.TextSmall>
            </Vermo.Flex>
          </Vermo.Flex>
        </div>
      </Container>
    );
  });

  return (
    <>
      {dualSports}
      {banterOpen && (
        <Vermo.StyledDrawer
          open={banterOpen}
          closeIcon={null}
          title={null}
          width={410}
        >
          <BanterModal banterMatchId={banterMatchId} setBanterMatchId={() => handleCloseBanter()} currentMatch={currentMatch} />
        </Vermo.StyledDrawer>
      )}
    </>
  );
};

export default PairSport;

interface Active {
  active?: boolean;
}

const Container = styled.div<Active>`
  padding: 17px 0;
  border-radius: 7px;
  cursor: pointer;
  background: ${(props) => props.theme.foreground};
  box-shadow: ${(props) => props.theme.subtleShadow};
  margin: 15px 0;
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-left: ${(props) =>
      `4px solid ${props.active ? props.theme.accentPrimary : "transparent"}`};
    padding: 0 10px;
  }
  .time {
    margin: 0;
    color: ${(props) => props.theme.textColor};
    font-size: ${desktop.xSmall};
    font-weight: 500;
  }
  .date {
    margin: 0;
    font-weight: 500;
    color: ${(props) => props.theme.mutedColorTertiary};
    font-size: ${desktop.xSmall};
  }
  .live {
    margin-left: 0px;
    color: ${(props) => props.theme.textColor};
    border-radius: 4px;
    font-weight: 600;
    padding: 1px 5px;
    width: fit-content;
    font-size: ${desktop.xSmall};
    background: ${(props) => props.theme.accentPrimary};
  }
  .leagueimg {
    height: clamp(16px, 1.4vw, 25px);
    margin-right: 15px;
  }
  .teamName {
    margin: 0;
    width: 80%;
    font-weight: 500;
    color: ${(props) => props.theme.textColor};
    font-size: ${desktop.small};
  }
  .scoreContent {
    padding: 10px;
    flex-grow: 0;
    flex-shrink: 0;
    border-radius: 50%;
    background: ${(props) => props.theme.mutedColorSecondary};
    color: ${(props) => props.theme.textColor};
    font-weight: 600;
    font-size: ${desktop.small};
    text-align: center;
    margin: 0 10%;
  }
  .minWrapper {
    flex-wrap: wrap;
    width: 20%;
    /* background-color: red; */
  }
  .maxWrapper {
    flex-wrap: wrap;
    width: 80%;
    /* background-color: blue; */
    /* margin-left */
  }
  .percentWrapper {
    width: 30%;
    display: flex;
    align-items: center;
    /* flex-wrap: wrap; */
  }
  .durationWrapper {
    display: block;
  }
  .showMobile {
    display: none !important;
  }
  @media ${device.tablet} {
    .teamName {
      margin: 0;
      width: 80%;
      font-weight: 500;
      color: ${(props) => props.theme.textColor};
      font-size: ${desktop.small};
    }
    .durationWrapper {
      display: flex;
      align-items: center;
    }
    .live {
      margin-left: 15px;
    }
    .maxWrapper {
      width: 60%;
    }
    .percentWrapper {
      width: 30%;
    }
    .showMobile {
      display: block !important;
    }
  }
`;


const ChatBox = styled.div<ChatBoxProps>`
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.isCurrentUser ? 'flex-end' : 'flex-start')};
  justify-content: ${(props) => (props.isCurrentUser ? 'flex-end' : 'flex-start')};
  text-align: ${(props) => (props.isCurrentUser ? 'right' : 'left')};
`;

const ChatMessage = styled.span<ChatBoxProps>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.isCurrentUser ? 'flex-end' : 'flex-start')};
  justify-content: ${(props) => (props.isCurrentUser ? 'flex-end' : 'flex-start')};
  text-align: ${(props) => (props.isCurrentUser ? 'right' : 'left')};
  margin-top: 5px;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #fff;
`;


const  MatchVS = styled.span`
    padding: 10px;
    border-radius: 50%;
    background: ${(props) => props.theme.mutedColorSecondary};
    color: ${(props) => props.theme.textColor};
    font-weight: 600;
    font-size: ${desktop.small};
    text-align: center;
    margin: 0 3%;
`


const EmptyData = styled.div`
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-size: ${desktop.small};
      color: ${(props) => props.theme.textColor};
      margin-top: 10rem; 
` 