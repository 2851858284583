// import { IRoutes } from ".";
import  React  from "react";
import Challenges from "./Views/Challenges/Index";
import Dashboard from "./Views/Dashboard/Index";
import ErrorPayment from "./Views/ErrorPayment";
import GameDetails from "./Views/GameDetails";
import Games from "./Views/Games";
import Login from "./Views/Login";
import Profile from "./Views/Profile/Index";
import Register from "./Views/Register/Index";
import Successful from "./Views/Successful";
import Users from "./Views/Users/index";
import Subscription from "./Views/Subscription/Index";
import Wallet from "./Views/Wallet";
import FriendChallenge from "./Views/friend/index";
const authLayout = "auth";
const dashboardLayout = "dashboard";

interface IRoutes {
  path: string;
  element: React.ComponentType<any>;
  layout: string;
  children?: any;
}

export const routes: IRoutes[] = [
  { path: "dashboard", element: Dashboard, layout: dashboardLayout },
  { path: "challenges", element: Challenges, layout: dashboardLayout },
  { path: "users", element: Users, layout: dashboardLayout },

  { path: "friend-challenge/:id?", element: FriendChallenge, layout: dashboardLayout},
  { path: "profile", element: Profile, layout: dashboardLayout },
  { path: "sports", element: Games, layout: dashboardLayout },
  { path: "details", element: GameDetails, layout: dashboardLayout },
  { path: "wallet", element: Wallet, layout: dashboardLayout },
  { path: "payment_successful", element: Successful, layout: dashboardLayout },
  // { path: "subscription", element: Subscription, layout: dashboardLayout },
  { path: "error_payment", element: ErrorPayment, layout: dashboardLayout },
];
