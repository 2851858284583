import React from "react";
import { FaUserCircle, FaWallet } from "react-icons/fa";
import { IoLogoGameControllerB } from "react-icons/io";
import { MdSpaceDashboard, MdSportsKabaddi } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { device } from "../../theme/breakPoints";

const MobileFooter = () => {
  const routes = [
    {
      id: 1,
      title: "Home",
      icon: <MdSpaceDashboard />,
      route: "/dashboard",
    },
    {
      id: 3,
      title: "Sports",
      icon: <IoLogoGameControllerB />,
      route: "/sports",
    },
    {
      id: 2,
      title: "Challenges",
      icon: <MdSportsKabaddi />,
      route: "/challenges",
    },

    {
      id: 5,
      title: "Wallet",
      icon: <FaWallet />,
      route: "/wallet",
    },

    {
      id: 4,
      title: "Profile",
      icon: <FaUserCircle />,
      route: "/profile",
    },
  ];
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Container>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {routes.map((val, id) => (
          <Nav
            key={id}
            onClick={() => {
              navigate(val.route);
            }}
            active={location.pathname === val.route ? true : false}
          >
            <Icon active={location.pathname === val.route ? true : false}>
              {val.icon}
            </Icon>
            <p>{val.title}</p>
          </Nav>
        ))}
      </div>
    </Container>
  );
};

export default MobileFooter;
interface ActiveNav {
  active?: any;
}
const Container = styled.div`
  padding: 0 10px;
  box-shadow: ${(props) => props.theme.subtleShadow};
  background: ${(props) => props.theme.background};
  z-index: 15;
  width: 100%;
  bottom: 0;
  height: 49px;
  position: fixed;
  left: 0;
  display: flex;
  align-items: center;
  border-top: ${(props) => `1px solid ${props.theme.mutedColorPrimary}`};

  @media ${device.laptop} {
    display: none;
  }
`;
const Nav = styled.div<ActiveNav>`
  align-items: center;
  color: ${(props) =>
    props.active ? props.theme.primaryColor : props.theme.mutedColorSecondary};
  display: flex;
  margin: 2px 0;
  padding: 12px 0;
  padding-left: 3vw;
  /* border-left: ${(props) =>
    props.active ? `4px solid ${props.theme.primaryColor}` : `none`}; */
  /* background: ${(props) =>
    props.active
      ? "linear-gradient(90deg, hsla(253, 79%, 57%, 0.4) 0%, hsla(0, 0%, 100%, 0) 4%);"
      : "none"}; */
  p {
    margin: 0 0 0 10px;
    color: ${(props) =>
      props.active ? props.theme.textColor : props.theme.mutedColorSecondary};
    font-size: 16px;
    display: ${(props) => (props.active ? "block" : "none")};
    font-size: 13px;
  }
`;
export const Icon = styled.div<ActiveNav>`
  margin: 0;
  transition: none !important;
  display: flex;
  align-items: center;
  font-size: 20px;
  color: ${(props) =>
    props.active ? props.theme.primaryColor : props.theme.mutedColorSecondary};
`;
