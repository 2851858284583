
import { Navigate } from "react-router-dom";

const authentication = (WrappedComponent: any) => {
  return (props: any) => {
    if (
      localStorage.token
      //  && localStorage.sub
    ) {
      return <Navigate to="/dashboard" />;
    }
    return <WrappedComponent {...props} />;
  };
};

export default authentication;
