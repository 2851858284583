import moment from "moment";
import * as yup from "yup";
export const validationSchema = {
  signup: {
    firstName: yup.string().required("Please provide your first name"),
    lastName: yup.string().required("Please provide your last name"),
    // businessName: yup.string().required("Please provide your business name"),
    email: yup
      .string()
      .email("Please use a valid mail")
      .required("Please provide your email address"),
    password: yup.string().required("Please provide your password"),
    gender: yup.string().required("Please select your gender"),
    dob: yup
      .string()
      .required("Please check your date of birth")
      .test("DOB", "You are not legally allowed.", (value) => {
        return moment().diff(moment(value), "years") >= 18;
      }),
    phoneNumber: yup.string().required("Please provide mobile number"),
  },
  login: {
    email: yup
      .string()
      .email("Please use a valid mail")
      .required("Please provide your email address"),
    password: yup.string().required("Please provide your password"),
  },
  forgotPassword: {
    email: yup
      .string()
      .email("Please use a valid mail")
      .required("Please provide your email address"),
  },
};
