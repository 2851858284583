import { UserOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import moment from "moment";
import React from "react";
import { formatNumberAmount } from "../../utils/helperfuntions";
import { ConfirmBetContent } from "../../Views/GameDetails/Style";
import { ModalComponent } from "../common/Modal";
import { NotificationText } from "../common/StyleComponents";
interface ModalInterface {
  onCancel: () => void;
  visible: boolean;
  details: any;
  children?: any
}
const WalletDetailsModal: React.FC<ModalInterface> = ({
  onCancel,
  visible,
  children,
  details,
}) => {
  return (
    <ModalComponent visible={visible} onCancel={onCancel}>
      <div style={{ padding: "1vw", width: "100%" }}>
        <NotificationText>Transaction Details</NotificationText>
        <br />
        <ConfirmBetContent>
          <p className="details">
            The details below are your transaction details
          </p>
          <p className="detailsInfo">
            Amount: <span>$ {formatNumberAmount(details?.amount)}</span>
          </p>
          <p className="detailsInfo">
            Date and Time :{" "}
            <span>
              {moment(details?.createdAt).format("MMM D, YYYY")}.{"  "}
              {moment(details?.createdAt).format("HH:MM A")}
            </span>
          </p>
          <p className="detailsInfo">
            Transaction type: <span>{details?.type}</span>
          </p>
          <p className="detailsInfo">
            Status: <span>{details?.status}</span>
          </p>

          <p className="detailsInfo">
            Transaction description : <span>{details?.description}</span>
          </p>
          <br />
          {details?.gameBet !== null && (
            <>
              <p className="details">Challenge Details</p>
              <p className="detailsInfo">
                Game Type : <span>{details?.gameBet?.gameType}</span>
              </p>
              <p className="detailsInfo">
                Bet Type : <span>{details?.gameBet?.betType}</span>
              </p>
              <p className="detailsInfo">
                Handicap : <span>{details?.gameBet?.optionsCreatedBy}</span>
              </p>
              <p className="detailsInfo">
                Created for team :{" "}
                <span>{details?.gameBet?.createdByTeam}</span>
              </p>
              <p className="detailsInfo">
                Accepted for team :{" "}
                <span>{details?.gameBet?.acceptedByTeam}</span>
              </p>
              <br />
              <Row style={{ width: "100%" }} justify="space-between">
                <Col xs={{ span: 24 }} lg={{ span: 11 }}>
                  <p className="details">Challenge Creator's Details</p>
                  <Avatar
                    style={{ flexShrink: 0, marginRight: "15px" }}
                    size="large"
                    src={details?.gameBet?.acceptedUser?.pic}
                    alt={`user `}
                    icon={<UserOutlined />}
                  />
                  <p className="detailsInfo">
                    Name : <span>{details?.gameBet?.createdBy?.name}</span>
                  </p>
                  <p className="detailsInfo">
                    Gender :{" "}
                    <span>
                      {details?.gameBet?.createdBy?.gender === "M"
                        ? "Male"
                        : "Female"}
                    </span>
                  </p>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 11 }}>
                  <p className="details"> Challenger's Details</p>
                  <Avatar
                    style={{ flexShrink: 0, marginRight: "15px" }}
                    size="large"
                    src={details?.gameBet?.acceptedUser?.pic}
                    alt={`user `}
                    icon={<UserOutlined />}
                  />
                  <p className="detailsInfo">
                    Name : <span>{details?.gameBet?.acceptedBy?.name}</span>
                  </p>
                  <p className="detailsInfo">
                    Gender :{" "}
                    <span>
                      {details?.gameBet?.acceptedBy?.gender === "M"
                        ? "Male"
                        : "Female"}
                    </span>
                  </p>
                </Col>
              </Row>
            </>
          )}
        </ConfirmBetContent>
      </div>
    </ModalComponent>
  );
};

export default WalletDetailsModal;
