import { useEffect, useMemo, useState } from "react";
import {
  ChallengesSelector,
  getAllChallengeAction,
  getCreatedChallengeAction,
  useAppDispatch,
} from "@michaelojo/vermo-redux";
import { StyledTable } from "../common/TableComponent";
import moment from "moment";
import { Live } from "./LockedChallenges";
import { Challenger, Selection, Team } from "../common/StyleComponents";
import { formatNumberAmount, handleCopyClick } from "../../utils/helperfuntions";
import { IoCopy as CopyIcon } from "react-icons/io5";
import { Vermo } from "../common/styled";
import ChallengeDetailsModal from "../Modals/ChallengeDetailsModal";

const MyChallenges = () => {
  const dispatcher = useAppDispatch() as any;
  const { myCreatedChallengeLoading, myCreatedChallenges} = ChallengesSelector() as any;
  const [isOpenChallengeDetails, setIsChallengeDetails] = useState<boolean>(false);
  const [selectedChallengeId, setSelectedChallengeId] = useState<string | null>(null);


useEffect(() => {
    dispatcher(getCreatedChallengeAction({}));
    dispatcher(getAllChallengeAction({}))
  },[]);

  const handleRowClick = (record: any) => {
    setSelectedChallengeId(record?.id);
    setIsChallengeDetails(true);
  };

  const challengeDetails = useMemo(() => {
    return myCreatedChallenges?.myAvaliableChallenges?.find((item: any) => item?.id  === selectedChallengeId)
  }, [myCreatedChallenges, selectedChallengeId]);


  const handleCopyClickWithStopPropagation = (event: React.MouseEvent, url: string) => {
    event.stopPropagation();
    handleCopyClick(url);
  };


  const columns = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: string, others: any) => {
        return (
          <div>
            <div style={{ display: "flex" }}>
              {moment(text).format("DD.MM.YYYY")}
              {others?.live && <Live>LIVE</Live>}
            </div>
            <div>{moment(text).format("hh:mm a")}</div>
          </div>
        );
      },
    },
    {
      title: "Fixtures",
      dataIndex: "team",
      key: "team",
      render: (props: any, others: any) => (
        <Team>
          <div className="content">
            {/* <img className="image" src={props?.homeLogo} alt={props.homeName} /> */}
            <p className="text">{others?.createdByTeam}</p>
          </div>
          <div className="content">
            <p className="text">VS</p>
          </div>
          <div className="content">
            {/* <img className="image" src={props?.awayLogo} alt={props.awayName} /> */}
            <p className="text">{others?.acceptedByTeam}</p>
          </div>
        </Team>
      ),
    },
    {
      title: "Game Type",
      dataIndex: "gameType",
      key: "gameType",
    },

    {
      title: "My Selections",
      dataIndex: "selection",
      key: "selection",
      render: (text: string, other: any) => (
        <div>
          <Selection style={{ border: `${other?.createdByTeam ? "" : ""}` }}>
            {other?.createdByTeam}
          </Selection>
          <Selection
            style={{ border: `${other?.acceptedByTeam ? "none" : ""}` }}
          >
            {other?.acceptedByTeam}
          </Selection>
        </div>
      ),
    },

    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text: string) => (
        <div>
          <Team>${formatNumberAmount(+text * 1)}</Team>
        </div>
      ),
    },
    {
      title: "Bet Type",
      dataIndex: "betType",
      key: "betType",
    },

    {
      title: "Address",
      dataIndex: "id",
      render: (record: any) => (
        <div>
          <Challenger style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "8px" }}>
            {`${window.location.hostname?.slice(0, 9) ?? "N/A"}`}
            <Vermo.TextSmall onClick={(e) => handleCopyClickWithStopPropagation(e, window.location.hostname + `/friend-challenge/` + record)}>
              <CopyIcon />
            </Vermo.TextSmall>
          </Challenger>
        </div>
      ),
    },
  ];




  return (
    <div>
      <StyledTable
        scroll={{ x: 600 }}
        loading={myCreatedChallengeLoading}
        columns={columns}
        dataSource={myCreatedChallenges?.myAvaliableChallenges}
        onRow={(record: any) => {
          return {
            onClick: () => handleRowClick(record),
          };
        }}
        rowKey="id"
      />

      {isOpenChallengeDetails && (
          <ChallengeDetailsModal visible={isOpenChallengeDetails} onCancel={() => setIsChallengeDetails(false)} selectedChallengeId={selectedChallengeId} challengeDetails={challengeDetails} />
      )}
    
    </div>
  );
};

export default MyChallenges;
