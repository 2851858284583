import { Col, Row } from "antd";
import React from "react";
import { WalletScreen } from "../../../constants/types";
import { ButtonComponent } from "../../common/Button";
import { DetailsText, ModalText, PaymentContent, Selected } from "../Style";
import {
  setUserPaymentDetails,
  useAppDispatch,
  WalletSelector,
} from "@michaelojo/vermo-redux";
import { FaCheck } from "react-icons/fa";

interface ICreatePayment {
  close: (val: WalletScreen) => void;
  next: (val: WalletScreen) => void;
}
const CreatePaymentMethod: React.FC<ICreatePayment> = ({ close, next }) => {
  const handleContinue = () => {
    next(data?.frontendIdentifier);
  };
  const [selectedMethod, setSelectedMethod] = React.useState();
  const [data, setData] = React.useState() as any;
  const { getAcceptedPaymentMethod, userPaymentDetails } =
    WalletSelector() as any;
  //   console.log(
  //     "getAcceptedPaymentMethod",
  //     JSON.parse(
  //       `{${getAcceptedPaymentMethod.getAcceptedPaymentMethods.dataSturcture}}`
  //     )
  //   );
  const dispatcher = useAppDispatch();

  const allowedPayments =
    getAcceptedPaymentMethod?.getAcceptedPaymentMethods?.map(
      (data: any, index: any) => {
        console.log(data.dataSturcture);
        return (
          <>
            <PaymentContent
              onClick={() => {
                setSelectedMethod(index + 1);
                setData(data);
                dispatcher(
                  setUserPaymentDetails({
                    ...userPaymentDetails,
                    paymentMethod: { data },
                  })
                );
              }}
              key={index}
            >
              <div>
                <DetailsText>Country: {data?.country}</DetailsText>
                <DetailsText>Payment Type: {data?.name}</DetailsText>
              </div>
              {selectedMethod === index + 1 && (
                <Selected>
                  <FaCheck />
                </Selected>
              )}
            </PaymentContent>
          </>
        );
      }
    );
  return (
    <div>
      <ModalText>Create Payment Method</ModalText>
      <br />
      {allowedPayments}
      <Row style={{ width: "100%" }} justify="space-between">
        <Col sm={{ span: 11 }} md={{ span: 11 }}>
          <ButtonComponent
            colorprop={true}
            onClick={() => close(WalletScreen.AMOUNT)}
            text={"Previous"}
            style={{ marginTop: "10px" }}
          />
        </Col>
        <Col sm={{ span: 11 }} md={{ span: 11 }}>
          <ButtonComponent
            onClick={handleContinue}
            // loading={loading}
            text={"Continue"}
            disabled={!selectedMethod && true}
            style={{ marginTop: "10px" }}
          />
        </Col>
      </Row>
      <br />
    </div>
  );
};

export default CreatePaymentMethod;
