//@ts-nocheck
import React from "react";
import styled from "styled-components";
import { DatePicker as AntDatePicker } from "antd";
import { device } from "../../theme/breakPoints";
import { desktop, mobile } from "../../theme/fontSize";
import moment from "moment";

interface InputFieldProps {
  name?: string;
  // eslint-disable-next-line no-unused-vars
  onChange?: (e: any) => void | any;
  onBlur?: () => void;
  placeholder?: string;
  changeValue?: any;
  value?: string;
  field?: any;
  error?: any;
  inputType?: string;
  hasLabel?: boolean;
  label?: string;
  isDisabled?: boolean;
  defaultValue?: string;
  prefix?: any;
  required?: boolean;
  style?: React.CSSProperties;
}

const DateInput = ({
  onChange,
  error,
  name,
  field,
  label,
  style,
  changeValue,
  defaultValue,
}: InputFieldProps) => {
  const [focus, setFocus] = React.useState(false);
  return (
    <div>
      {label && <Label>{label}</Label>}

      <Container
        focusProp={focus}
        onBlur={() => setFocus(false)}
        onFocus={() => setFocus(true)}
        style={{ ...style }}
      >
        <StyledDatePicker
          allowClear={false}
          name={name}
          defaultValue={defaultValue ? defaultValue : moment()}
          onChange={(e) =>
            field
              ? changeValue(field, moment(e).format("YYYY-MM-DD"))
              : onChange(moment(e).format("YYYY-MM-DD"))
          }
        />
      </Container>
      {error && <Error>{error} !</Error>}
    </div>
  );
};
export const Error = styled.h5`
  margin: 0px 0 0 12px;
  font-weight: 300;
  font-size: ${mobile.small};
  color: red;
  @media ${device.laptop} {
    font-size: ${desktop.small};
  }
`;
const StyledDatePicker = styled(AntDatePicker)`
  height: 100%;
  width: 100%;
  padding: 5px 10px;
  font-weight: 300;
  font-size: 14px;
  border: none !important;
  background: ${(props) => props.theme.foreground};
  color: ${(props) => props.theme.textColor};
  input {
    color: ${(props) => props.theme.textColor};
    ::placeholder {
      color: ${(props) => props.theme.mutedColorTertiary};
    }
  }
  .ant-picker-panel-container,
  .ant-picker-panel {
    background: ${(props) => props.theme.foreground} !important;
  }
  .ant-picker-panel,
  .ant-picker-body {
    background: ${(props) => props.theme.foreground} !important;
  }

  .anticon,
  .anticon-close-circle {
    color: ${(props) => props.theme.textColor};
  }
  ::placeholder {
    color: ${(props) => props.theme.mutedColorTertiary};
  }
  @media ${device.laptop} {
    font-size: ${desktop.small};
    .ant-input {
      font-size: ${desktop.small};
    }
  }
`;

export default DateInput;
interface ContainerProp {
  focusProp: boolean;
}
export const Label = styled.h5`
  margin-bottom: 0;
  font-weight: 300;
  font-size: ${mobile.normal};
  color: ${(props) => props.theme.mutedColorTertiary};
  @media ${device.laptop} {
    font-size: ${desktop.small};
  }
`;
export const Container = styled.div<ContainerProp>`
  width: 100%;
  height: 40px;
  border: ${(props) =>
    `1px solid ${
      props.focusProp
        ? props.theme.primaryColor
        : props.theme.mutedColorSecondary
    }`};
  border-radius: 4px;
  padding: 0vw 0vw;
  display: flex;
  align-items: center;
  margin: 0;
  @media ${device.laptop} {
    height: 2.5vw;
  }
`;
