import {
  GeneralDataSelector,
  getAllChallengeAction,
  getLeaguesDetailsAction,
  getMyLockedChallengeAction,
} from "@michaelojo/vermo-redux";
import { Row } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { ButtonComponent } from "../../components/common/Button";
import DateInput from "../../components/common/DateInput";
import DropDown from "../../components/common/DropDown";
import {
  EndContainer,
  FilterContainer,
  MidContainer,
  PageTitle,
  StyledDropdown,
  StyledIcon,
  StyledMenu,
  StyledTabs,
  StyledTapPane,
} from "../../components/common/StyleComponents";
import AvailableChallenges from "../../components/Tabs/AvailableChallenges";
import ChallengeHistory from "../../components/Tabs/ChallengeHistory";
import FriendsChallenge from "../../components/Tabs/FriendsChallenge";
import LockedChallenges from "../../components/Tabs/LockedChallenges";
import MyChallenges from "../../components/Tabs/MyChallenges";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { useMediaQuery } from 'react-responsive'
import { ModalComponent } from "../../components/common/ModalComponent";


const Challenges = () => {
  const dispatcher: ThunkDispatch<any, any, any> = useDispatch()
  const [ game, setGame ] = useState<string | null>(null);
  const [ country, setCountry ] = useState<string | null>(null);
  const [ division, setDivision ] = useState<string | null>(null);
  const [ date, setDate ] = useState<string | null>(null);
  const [ availableDivisions, setAvailableDivisions ] = useState<string[]>([]);
  const [gameFixtureId, setGameFixtureId] = useState<string | null>(null);
  const { leaguesDetails } = GeneralDataSelector() as any
  const [loading, setLoading] = useState<boolean>(false);
  const isPhoneOrTablet = useMediaQuery({query: '(max-width: 768px)'})
  const [ isFilterContainerOpen, setIsFilterContainerOpen ] = useState<boolean>(false)

  const gameTypes = ["FOOTBALL", "TABLETENNIS", "BASKETBALL", "BOXING", "UFC"]

  React.useEffect(() => {
    dispatcher(getAllChallengeAction({}));
    dispatcher(getMyLockedChallengeAction({}));
    dispatcher(getLeaguesDetailsAction({}))
  }, []);

  const allCountries = useMemo(
    () => (leaguesDetails?.getLeagues?.map((league: any) => league?.country) || []).filter((value: any, index: any, self: any) => self?.indexOf(value) === index),
    [leaguesDetails]
  );

  useEffect(() => {
    if (country) {
      // Filter divisions based on the selected country
      const filteredDivisions = leaguesDetails?.getLeagues
        .filter((league: any) => league?.country === country)
        .map((league: any) => league?.leagueName) || [];
      setAvailableDivisions(filteredDivisions);
    } else {
      setAvailableDivisions([]);
    }
  }, [country, leaguesDetails]);

  const handleFilter = async () => {
    setLoading(true);
    if (country && division) {
      // finding the league that matches the selected country and division
      const selectedLeague = leaguesDetails?.getLeagues?.find(
        (league: any) => league?.country === country && league?.leagueName === division
      );
      if (selectedLeague) {
        setGameFixtureId(selectedLeague?.gameFixtureId);
      }
    }
    setLoading(false);
  };



  const FilterContent = () => (
    <FilterContainer>
      <p className="filter"> Filter all challenges</p>
      <br />
      <DropDown
        placeholder="Select game"
        values={gameTypes}
        label="Games"
        onChange={(value: any) => setGame(value)}
      />
      <DropDown
        placeholder="Select country"
        values={allCountries}
        label="Country"
        onChange={(value: any) => setCountry(value)}
      />
      <DropDown
        placeholder="Select division"
        values={availableDivisions}
        label="Division"
        onChange={(value: any) => setDivision(value)}
      />
      <DateInput
        label="Date"
        defaultValue={date || undefined}
        style={{ marginBottom: 15 }}
        onChange={(e) => {
          const value = e.target ? e.target.value : e;
          setDate(value);
        }}
      />
      <br />
      <ButtonComponent
        style={{}}
        loading={loading}
        text="Filter available challenges"
        onClick={handleFilter}
      />
    </FilterContainer>
  );
 

  return (
    <>
      <PageTitle>
        <h1>Challenges</h1>
          <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <h2>Manage all challenges.</h2>

          {isPhoneOrTablet ? <div onClick={() => setIsFilterContainerOpen(true)}>
               <StyledMenu>
                  <StyledIcon />
               </StyledMenu>
        </div> : null}

          </div>
        <br />
        <Row justify="space-between">
          <MidContainer lg={{ span: 17 }} sm={{ span: 24 }}>
            <StyledTabs defaultActiveKey="1">
              <StyledTapPane tab="My Challenges" key="1">
                <MyChallenges />
              </StyledTapPane>
              <StyledTapPane tab="Friend's Challenges" key="2">
                <FriendsChallenge />
              </StyledTapPane>
              <StyledTapPane tab="Locked Challenges" key="3">
                <LockedChallenges />
              </StyledTapPane>
              <StyledTapPane tab="Available Challenges" key="4">
                <AvailableChallenges game={game} country={country} division={division} date={date} apiFootballLeagueId={gameFixtureId} />
              </StyledTapPane>
              <StyledTapPane tab="Challenge History" key="5">
                <ChallengeHistory />
              </StyledTapPane>
            </StyledTabs>
          </MidContainer>

          <EndContainer md={{ span: 6 }}>
            {isPhoneOrTablet && isFilterContainerOpen ? (
             <ModalComponent visible={isFilterContainerOpen} onCancel={() => setIsFilterContainerOpen(false)}>
                <FilterContent />
             </ModalComponent>
            )  : (
              <FilterContent />
            ) }

          </EndContainer>
        </Row>
      </PageTitle>
    </>
  );
};

export default Challenges;
