import {
  GeneralDataSelector,
  getAllChallengeAction,
  getLeaguesDetailsAction,
  getMyLockedChallengeAction,
} from "@michaelojo/vermo-redux";
import React, { useEffect, useMemo } from "react";
import { Selection, Team } from "../common/StyleComponents";
import { StyledTable } from "../common/TableComponent";
import { ChallengesSelector, useAppDispatch } from "@michaelojo/vermo-redux";
import moment from "moment";
import { formatNumberAmount } from "../../utils/helperfuntions";
import AcceptChallengeModal from "../Modals/AcceptChallengeModal";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";


interface AvailableChallengesProps {
  game: string | null;
  country: string | null;
  division: string | null;
  date: string | null;
  apiFootballLeagueId: any;
}

const AvailableChallenges:React.FC<AvailableChallengesProps> = ({ game, country, division, date, apiFootballLeagueId }) => {
  const { allChallengeLoading,  myAvailableChallenges } = ChallengesSelector() as any;
  const [showModal, setShowModal] = React.useState(false);
  const [currentData, setCurrentData] = React.useState({}) as any;
  const dispatcher: ThunkDispatch<any, any, any> = useDispatch();
  const { leaguesDetails } = GeneralDataSelector() as any;


  useEffect(() => {
    dispatcher(getAllChallengeAction({}));
    dispatcher(getLeaguesDetailsAction({}))
  },[dispatcher]);

  // mapping `gameFixtureId` to country and division
  const fixtureDetails = useMemo(() => {
    const detailsMap: { [key: string]: { country?: string, division?: string } } = {};

    leaguesDetails?.getLeagues?.forEach((league: any) => {
      detailsMap[league?.gameFixtureId] = {
        country: league?.country,
        division: league?.leagueName,
      };
    });
    return detailsMap;
  }, [leaguesDetails]);


  const filteredChallenges = useMemo(() => {
    return myAvailableChallenges?.getChallenges?.filter((challenge: any) => {
      if (!challenge) return false; 
  
      const gameMatch = game ? challenge?.gameType === game : true;
      const countryMatch = country ? fixtureDetails[challenge?.gameFixtureId]?.country === country : true;
      const divisionMatch = division ? fixtureDetails[challenge?.gameFixtureId]?.division === division : true;
      const dateMatch = date ? moment(challenge?.createdAt).isSame(moment(date), 'day') : true;
      const fixtureMatch = apiFootballLeagueId ? challenge?.gameFixtureId === apiFootballLeagueId : true;
    
      return gameMatch && countryMatch && divisionMatch && dateMatch && fixtureMatch;
    });
  }, [myAvailableChallenges, game, country, division, date, apiFootballLeagueId, fixtureDetails]);
  

  const columns = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: string, others: any) => (
        <div style={{ display: "flex" }}>
          {moment(text).format("DD.MM.YYYY")}
        </div>
      ),
    },
    {
      title: "Game Type",
      dataIndex: "gameType",
      key: "gameType",
    },
    {
      title: "Fixtures",
      dataIndex: "team",
      key: "team",
      render: (props: any, all: any) => (
        <Team>
          <div className="content">
            {/* <img className="image" src={props?.homeLogo} alt={props.homeName} /> */}
            <p className="text" style={{ textAlign: "center" }}>
              {all.createdByTeam}
            </p>
          </div>
          <p className="text">vs</p>
          <div className="content">
            {/* <img className="image" src={props?.awayLogo} alt={props.awayName} /> */}
            <p className="text">{all.acceptedByTeam}</p>
          </div>
        </Team>
      ),
    },
    {
      title: "Bet Type",
      dataIndex: "betType",
      key: "betType",
    },

    {
      title: "Challenge Selection",
      dataIndex: "selection",
      key: "selection",
      render: (text: string, all: any) => (
        <div>
          <Selection
            style={{
              border: `${all.createdByTeam && ""}`,
            }}
          >
            {all.createdByTeam}
          </Selection>
          <Selection style={{ border: `${text !== "AWAY" ? "none" : ""}` }}>
            {all.acceptedByTeam}
          </Selection>
        </div>
      ),
    },

    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text: string) => (
        <div>
          <Team>${formatNumberAmount(+text)}</Team>
        </div>
      ),
    },
  ];


  return (
    <>
      {showModal && (
        <AcceptChallengeModal
          data={currentData}
          onCancel={() => {
            setShowModal(!showModal);
            dispatcher(getAllChallengeAction({}));
            dispatcher(getMyLockedChallengeAction({}));
          }}
          visible={showModal}
        />
      )}
      <div>
        <StyledTable
          scroll={{ x: 600 }}
          loading={allChallengeLoading}
          columns={columns}
          dataSource={filteredChallenges}
          onRow={(record: any) => {
            return {
              onClick: (e) => {
                console.log("records", record?.live);
                setShowModal(!showModal);
                setCurrentData(record);
              },
            };
          }}
        />
      </div>
    </>
  );
};

export default AvailableChallenges;
