import React from "react";
import { ButtonComponent } from "../../components/common/Button";
import { useNavigate } from "react-router-dom";
import {
  AuthSubtitle,
  AuthTitle,
  AuthWrapper,
} from "../../components/common/StyleComponents";
import TextInput from "../../components/common/TextInput";
import Auth from "../../components/layouts/Auth";
import { Formik } from "formik";
import * as yup from "yup";
import { validationSchema } from "../../utils/validationSchema";
import {
  loginAction,
  useAppDispatch,
  setLoginInfo,
  setRegistrationInfo,
  getUserAction,
  loginSelector,
} from "@michaelojo/vermo-redux";
import { NotificationAlert } from "../../utils/NotificationAlert";
import authentication from "../../hoc/authenticated";
interface Values {
  email: string;
  password: string;
}

const Login = () => {
  const dispatcher = useAppDispatch() as any;
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
 

  const login = async ( values: any) => {
    setLoading(true)
    setRegistrationInfo(values)
    try {
      const res = await dispatcher(loginAction(values)).unwrap();
      setLoading(false);
      if(res.login.status === true) {
        localStorage.setItem("token", res.login.token);
        setLoginInfo(res.login.user);
        NotificationAlert("success", res?.login?.msg);
        navigate("/dashboard");
      } else if (res.login.status === false) {
        NotificationAlert("error", res?.login?.msg);
      }
    } catch (error: any) {
      console.log("login error", error);
      setLoading(false)
      NotificationAlert("error", error?.response?.data?.login.msg);
    }
  }


  return (
    <Auth>
      <div style={{ zIndex: 1 }}>
        <AuthWrapper>
          <AuthTitle>Sign in to your Vermo account with email</AuthTitle>
          <AuthSubtitle>
            Don't have an Account?
            <span onClick={() => navigate("/register")}> Sign up</span>
          </AuthSubtitle>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            onSubmit={async (values: Values) => {
              login(values);
            }}
            validationSchema={yup.object().shape(validationSchema.login)}
          >
            {({ values, handleChange, errors, handleSubmit }) => (
              <>
                <TextInput
                  required
                  inputType="email"
                  name="email"
                  placeholder="Enter your email"
                  label="Email"
                  value={values.email}
                  onChange={handleChange}
                  error={errors.email}
                />
                <TextInput
                  required
                  inputType="password"
                  name="password"
                  placeholder="Enter your Password"
                  label="Password"
                  value={values.password}
                  onChange={handleChange}
                  error={errors.password}
                />
                <ButtonComponent
                  loading={loading}
                  onClick={handleSubmit}
                  text={"Log in"}
                  style={{ marginTop: "10px" }}
                />
              </>
            )}
          </Formik>
        </AuthWrapper>
        <AuthSubtitle style={{ marginTop: "20px" }}>
          <span onClick={() => navigate("/forgot-password")}>
            Forgot your password?
          </span>
        </AuthSubtitle>
      </div>
    </Auth>
  );
};

export default authentication(Login);
