import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Vermo } from '../../common/styled';
import { ButtonComponent } from '../../common/Button';
import { AiOutlineClose as CloseIcon } from 'react-icons/ai';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { GeneralDataSelector, GET_USER_COMMENT_SUBSCRIPTION , getFriendsChannelAction, useSubscription } from '@michaelojo/vermo-redux';
import { handleFriendComment } from '../../../utils/actions/actions';
import { useFormik } from 'formik';
import { desktop } from '../../../theme/fontSize';

interface FriendChatProps {
  SetFriendId: () => void;
  currentMatch: any;
  friendId: string | null;
}

interface ChatBoxProps {
  isCurrentUser: boolean;
}

const FriendChatModal: React.FC<FriendChatProps> = ({ friendId, SetFriendId, currentMatch }) => {
  const dispatch: ThunkDispatch<any, any, any> = useDispatch();
  const { userCommentLoading, userDetails, friendChannel } = GeneralDataSelector() as any;
  const [chatRoomId, setChatRoomId] = useState<string | null>(null); // State for chatRoomId
  const scrollAnchorRef = useRef<HTMLDivElement>(null); // Reference for scrolling

  // Fetch chatRoomId when friendId changes
  const fetchChatRoom = useCallback(async () => {
    if (friendId) {
      dispatch(getFriendsChannelAction({ friendId }));
    }
  }, [friendId, dispatch]);

  useEffect(() => {
    if (friendId) {
      fetchChatRoom();
    }
  }, [friendId, fetchChatRoom]);

  console.log("friendChannel", friendChannel);
  useEffect(() => {
    if (friendChannel?.getFriendChannel
      ?.chatRoomId !== chatRoomId) {
      setChatRoomId(friendChannel?.getFriendChannel
        ?.chatRoomId);
    }
  }, [friendChannel]);

  const { data, loading } = useSubscription(GET_USER_COMMENT_SUBSCRIPTION, {
    variables: { chatRoomId },
    skip: !chatRoomId, // Skip subscription if chatRoomId is not available
  }) as any;

  const comments = data?.chatComments;

  // Effect to scroll to the last comment when modal opens or new comment is added
  useEffect(() => {
    if (scrollAnchorRef.current) {
      scrollAnchorRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [comments]);

  // Submit handler
  const handleCommentSubmit = async (values: any, { resetForm }: any) => {
    await handleFriendComment(dispatch, values, resetForm);
  };

  // Formik setup
  const formik = useFormik({
    initialValues: {
      chatRoomId: chatRoomId || '', // Ensure it's not null
      comment: '',
    },
    enableReinitialize: true, // Update form values when chatRoomId changes
    onSubmit: handleCommentSubmit,
  });

  // Function to check if a string is a URL
  const isUrl = (str: string) => {
    const urlRegex = /^(?:https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
    return str.match(urlRegex) !== null;
  };

  return (
    <div>
      <div style={{ marginBottom: '20px' }}>
        <Vermo.FlexEnd>
          <Vermo.TextSmall onClick={SetFriendId}>
            <CloseIcon size={20} />
          </Vermo.TextSmall>
        </Vermo.FlexEnd>
        <Vermo.FlexBetween>
          <Vermo.TextSmall>{currentMatch?.name}</Vermo.TextSmall>
        </Vermo.FlexBetween>
      </div>
      <div style={{ overflowY: 'scroll', maxHeight: '80vh' }}>
        {loading ? (
          'Loading...'
        ) : (
          comments?.map((item: any) => (
            <ChatBox key={item?.id} isCurrentUser={userDetails?.me?.id === item?.user?.id}>
              <Vermo.TextSmall>{item?.user?.name}</Vermo.TextSmall>
              <ChatMessage isCurrentUser={userDetails?.me?.id === item?.user?.id}>
                {isUrl(item?.comment) ? (
                  <a href={item?.comment} target="_blank" rel="noopener noreferrer">
                    {item?.comment}
                  </a>
                ) : (
                  item?.comment
                )}
              </ChatMessage>
            </ChatBox>
          ))
        )}
        <div id="scroll-anchor" ref={scrollAnchorRef}></div> {/* Scroll anchor */}
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex_class">
          <Vermo.InputMessage
            placeholder="start conversation"
            name="comment"
            value={formik.values.comment}
            onChange={formik.handleChange}
            style={{ width: '80rem' }}
          />
          <ButtonComponent htmlType="submit" text="Send" style={{ width: '70px' }} loading={userCommentLoading} />
        </div>
      </form>
    </div>
  );
};

export default FriendChatModal;

const ChatBox = styled.div<ChatBoxProps>`
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.isCurrentUser ? 'flex-end' : 'flex-start')};
  text-align: ${(props) => (props.isCurrentUser ? 'right' : 'left')};
`;

const ChatMessage = styled.span<ChatBoxProps>`
  margin-top: 5px;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #fff;
`;

const MatchVS = styled.span`
  padding: 10px;
  border-radius: 50%;
  background: ${(props) => props.theme.mutedColorSecondary};
  color: ${(props) => props.theme.textColor};
  font-weight: 600;
  font-size: ${desktop.small};
  text-align: center;
  margin: 0 3%;
`;
