import { Timeline } from "antd";
import React from "react";
import styled from "styled-components";
import { desktop } from "../../theme/fontSize";

interface IconColor {
  color?: any;
}
export const StyledTimeline = styled(Timeline)`
  width: 100%;
  .ant-timeline-item-label {
    font-size: ${desktop.xSmall} !important;
    margin-right: 20px !important;
    color: ${(props) => props.theme.textColor};
  }
  .ant-timeline-item-tail {
    color: red !important;
  }

  .text {
    color: ${(props) => props.theme.textColor};
    margin: 0;
    font-weight: 400;
  }
`;

export const StyledTimelineItem = styled(Timeline.Item)`
  .ant-timeline-item-tail {
    color: red !important;
  }
`;

export const StyledIcon = styled.div<IconColor>`
  font-size: 13px;
  color: ${(props) => (props.color ? props?.color : props.theme.primaryColor)};
  margin-left: 10px;
  background: none;
`;
