//@ts-nocheck
import { useLazyQuery } from "@apollo/client";
import {
  FOOTBALL_FIXTURES_QUERY,
  LeagueDetailsSelector,
  LiveGamesObjectSelector,
  LiveGamesSelector,
  ScheduledGamesSelector,
  updateFootballGames,
  useAppDispatch,
} from "@michaelojo/vermo-redux";
import React, { useEffect, useMemo, useState } from "react";
import { desktop } from "../../theme/fontSize";
import { updateMatchArrayByDate } from "../../utils/helperfuntions";
import styled from "styled-components";
import Loading from "../common/Loading";
import { FetchMore } from "../HelperComponent/FecthMore";
import PairSport from "../others/SelectSports/PairSport";
interface AllGamesProp {
  fixtureLeague: number;
  fixtureDate: any;
}
const AllGames = ({ fixtureLeague, fixtureDate }: AllGamesProp) => {

  const [fixturePage, setFixturePage] = useState(0);

  const [loadingMore, setLoadingMore] = useState(false);
  const [fetchFixtures, { data, loading, error, fetchMore, refetch }] =
    useLazyQuery(FOOTBALL_FIXTURES_QUERY, {
      variables: {
        currentPage: fixturePage,
        dateFilter: fixtureDate,
        leagueFilter: fixtureLeague,
      },
    });


  const dispatcher = useAppDispatch();

  React.useEffect(() => {
    if (data) {
      dispatcher(updateFootballGames(data));
    }
  }, [data]);
  useEffect(() => {
    setFixturePage(0);
    fetchFixtures({
      variables: {
        dateFilter: fixtureDate,
        leagueFilter: fixtureLeague,
        currentPage: fixturePage,
      },
    });
  }, [fixtureDate, fixtureLeague]);

  const fetchMoreFunction = () => {
    // if (fixturePage > 10) return;
    try {
      if (data?.footballFixtures?.hasNextPage && !loading) {
        setLoadingMore(true);

        setFixturePage(fixturePage + 1);
        //@ts-ignore
        fetchMore({
          variables: {
            currentPage: fixturePage!,
          },

          updateQuery: (previousResult, { fetchMoreResult }) => {
            if (
              !fetchMoreResult &&
              !fetchMoreResult?.footballFixtures?.data?.length
            ) {
              return previousResult;
            }
            const getPreviousList = data?.footballFixtures?.data;
            const getCurrentList = fetchMoreResult?.footballFixtures?.data;
            const tempMerge = [...getPreviousList, ...getCurrentList];
            return {
              ...fetchMoreResult,
              footballFixtures: {
                data: tempMerge,
                hasNextPage: fetchMoreResult?.footballFixtures?.hasNextPage,
              },
            };
          },
        }).finally(() => setLoadingMore(false));
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const leaguesDetails = LeagueDetailsSelector() as any;
  const scheduledGameList = ScheduledGamesSelector() as any;
  const liveGamesObject = LiveGamesObjectSelector();
  // console.log("live Games", liveGamesObject);

  const formattedLeague = () => {
    let formattedData = {} as any;
    //@ts-ignore
    if (leaguesDetails?.getLeagues) {
      for (const data of leaguesDetails?.getLeagues) {
        formattedData[data?.apiFootballLeagueId] = data;
      }
      return formattedData;
    }
    return [];
  };

  const formattedLeagues = React.useMemo(() => formattedLeague(), []);
  const liveGamesList = LiveGamesSelector() as any;

  const scheduledGames = updateMatchArrayByDate(
    scheduledGameList?.footballFixtures?.data || [],
    formattedLeagues,
    liveGamesObject
  );



  const footballList = useMemo(() => {
    if (!scheduledGames?.[0]) {
      return null;
    }
    return Object.keys(scheduledGames?.[0]?.match)?.map((value, idx) => {
      const footballData = scheduledGames[0]?.match;
      return (
        <>
          <Title>{value}</Title>
          <PairSport key={idx} matchDets={footballData[value]} />
          <br />
        </>
      );
    });
  }, [scheduledGameList, liveGamesList]);
  return (
    <>
      {footballList}
      {loading && (
        <>
          <Loading skeleton={true} />
        </>
      )}
      {data && <FetchMore fetchMore={fetchMoreFunction} />}
      {loadingMore && (
        <>
          <Loading skeleton={true} />
        </>
      )}
    </>
  );
};

const Title = styled.h4`
  margin: 0;
  font-family: Montserrat;
  color: ${(props) => props.theme.textColor};
  font-weight: 600;
  font-size: ${desktop.small};
`;
export default AllGames;
