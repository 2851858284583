import React from "react";
import styled from "styled-components";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { desktop, mobile } from "../../theme/fontSize";
import { device } from "../../theme/breakPoints";
interface PhoneNumberType {
  onChange?: any;
  values?: any;
  required?: boolean;
  placeholder?: string;
  label?: string;
  name?: string;
  field?: string;
  error?: string;
}
const PhoneNumberInput = ({
  label,
  onChange,
  placeholder,
  required,
  values,
  name,
  field,
  error,
}: PhoneNumberType) => {
  const [focus, setFocus] = React.useState(false);
  return (
    <div style={{ marginBottom: 15 }}>
      {label && <Label>{label}</Label>}

      <Container
        focusProp={focus}
        onBlur={() => setFocus(false)}
        onFocus={() => setFocus(true)}
      >
        <StyledInput
          name={name}
          international
          countryCallingCodeEditable={false}
          defaultCountry="US"
          placeholder={placeholder}
          //   inputClass="inputStyle"
          //   containerClass="containerStyle"
          //   dropdownClass="dropdownStyle"
          //   buttonClass="buttonStyle"
          //   searchClass="searchStyle"
          //   country={"us"}
          onChange={(c) => onChange(field, c)}
        />
      </Container>
      {error && <Error>{error} !</Error>}
    </div>
  );
};

export const StyledInput = styled(PhoneInput)`
  outline: none;
  height: 100%;
  padding: 5px 10px;
  font-weight: 300;
  font-size: 14px;
  width: 100%;
  background: ${(props) => props.theme.foreground} !important;
  color: ${(props) => props.theme.textColor};
  .PhoneInputInput {
    background: none !important;
    border: none !important;
    outline: none !important;
    width: 100% !important;
    flex: 1;
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: ${(props) => props.theme.foreground} !important;
    }
  }
`;

export const Label = styled.h5`
  margin-bottom: 0;
  font-weight: 300;
  font-size: ${mobile.normal};
  color: ${(props) => props.theme.mutedColorTertiary};
  @media ${device.laptop} {
    font-size: ${desktop.small};
  }
`;

export default PhoneNumberInput;

interface ContainerProp {
  focusProp: boolean;
}

export const Error = styled.h5`
  margin: 0px 0 0 12px;
  font-weight: 300;
  font-size: ${mobile.small};
  color: red;
  @media ${device.laptop} {
    font-size: ${desktop.small};
  }
`;
export const Container = styled.div<ContainerProp>`
  width: 100%;
  height: 40px;
  border: ${(props) =>
    `1px solid ${
      props.focusProp
        ? props.theme.primaryColor
        : props.theme.mutedColorSecondary
    }`};
  border-radius: 4px;
  padding: 0vw 0vw;
  display: flex;
  align-items: center;
  margin: 0;
  @media ${device.laptop} {
    height: 2.5vw;
  }
`;
