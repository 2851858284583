import styled from "styled-components";
import { device } from "../../theme/breakPoints";
import { desktop, mobile } from "../../theme/fontSize";
import { AiFillCaretDown } from "react-icons/ai";
import { Col, Dropdown, Menu, Tabs } from "antd";
import { BsFilter } from "react-icons/bs";
const { TabPane } = Tabs;

export const Style = styled.div``;

export const AuthWrapper = styled.div`
  z-index: 1;
  box-shadow: ${(props) => props.theme.authShadow};
  background-color: ${(props) => props.theme.foreground};
  padding: 15px;
  /* background: red; */
  /* border: 1px solid grey; */
  @media ${device.tablet} {
    width: 50vw;
  }
  @media ${device.laptop} {
    width: 37vw;
    padding: 3vw 5vw;
  }
`;

export const MidContainer = styled(Col)`
  background: none;
  width: 100%;
`;

export const EndContainer = styled(Col)`
  display: none;
  @media ${device.laptop} {
    height: 90vh;
    position: sticky;
    top: 8vh;
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 4px;
      background: ${(props) => props.theme.background};
      /* display: none; */
    }
    &::-webkit-scrollbar-track {
      background: ${(props) => props.theme.foreground};
      /* display: none; */
    }
    &::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.mutedColorPrimary};
      height: 20vh;
      border-radius: 10px;
      /* display: none; */
    }
  }
`;

export const NotificationText = styled.h4`
  font-size: 14px;
  text-align: center;
  color: ${(props) => props.theme.textColor};
  margin: 0;
  span {
    font-weight: 600;
    color: ${(props) => props.theme.accentSecondary};
  }
  @media ${device.laptop} {
    font-size: ${desktop.small};
  }
`;
export const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  /* background:red; */
  background: ${(props) => props.theme.foreground};
  padding: 10px;

  h3 {
    margin: 0;
    font-weight: 400;
    color: ${(props) => props.theme.textColor};
    text-align: center;
  }
`;
export const AuthTitle = styled.h3`
  margin: 0 0 10px 0;
  font-family: "Montserrat";
  font-weight: 600;
  color: ${(props) => props.theme.textColor};
  font-size: ${mobile.medium};
  @media ${device.laptop} {
    font-size: ${desktop.medium};
  }
`;
export const AuthSubtitle = styled.p`
  margin: 0 0 30px 0;
  font-family: "Montserrat";
  font-weight: 400;
  color: ${(props) => props.theme.textColor};
  font-size: ${mobile.normal};

  @media ${device.laptop} {
    font-size: ${desktop.small};
  }
  & > span {
    color: ${(props) => props.theme.primaryColor};
    z-index: 2000000;
    cursor: pointer;
  }
`;
export const SportTitle = styled.p`
  margin: 0;
  font-size: ${mobile.normal};
  color: ${(props) => props.theme.textColor};
  font-weight: 700;
  font-family: Montserrat;
  @media ${device.laptop} {
    font-size: ${desktop.extraLarge};
  }
`;
export const StyledDropdown = styled(Dropdown)`
  font-size: 16px;

  @media ${device.laptop} {
    font-size: 1vw;
  }
`;

export const StyledFilter = styled.h5`
  margin: 0;
  font-size: 20px;
  color: ${(props) => props.theme.textColor};
  @media ${device.laptop} {
    display: none;
  }
`;

export const StyledIcon = styled(AiFillCaretDown)`
  color: ${(props) => props.theme.textColor};
  cursor: pointer;
  margin: 0 5px;
`;
export const StyledMenu = styled(Menu)`
  border-radius: 5px;
  @media ${device.laptop} {
    /* width: 100px; */
  }
  background: ${(props) => props.theme.foreground};
`;
export const MenuItem = styled(Menu.Item)`
  font-size: 14px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.textColor};
  background: ${(props) => props.theme.foreground};
  :hover {
    color: ${(props) => props.theme.textColor};
    background: ${(props) => props.theme.mutedColorSecondary};
  }
  @media ${device.laptop} {
    font-size: ${desktop.small};
  }
`;

export const StyledTabs = styled(Tabs)`
  color: ${(props) => props.theme.mutedColorTertiary} !important;
  /* color: red !important; */
  font-size: ${desktop.small} !important;
  font-weight: 500;

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${(props) => props.theme.textColor} !important;
    font-weight: 400;
  }
  .ant-tabs-tab {
    :hover {
      color: ${(props) => props.theme.mutedColorTertiary} !important;
    }
  }
  .ant-tabs-ink-bar {
    position: absolute;
    background: ${(props) => props.theme.primaryColor} !important;
    pointer-events: none;
  }
  .ant-tabs-nav-wrap {
    border-bottom: ${(props) => `1px solid ${props.theme.mutedColorTertiary}`};
  }
`;
export const StyledTapPane = styled(TabPane)``;
export const DivisionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 15px;
  img {
    height: clamp(14px, 1.2vw, 16px);
    margin-right: 15px;
  }
  .country {
    margin: 0;
    font-family: Montserrat;
    color: ${(props) => props.theme.mutedColorTertiary};
    font-weight: 500;
    font-size: ${desktop.small};
  }
  .division {
    margin: 0;
    font-family: Montserrat;
    color: ${(props) => props.theme.textColor};
    font-weight: 600;
    font-size: ${desktop.small};
  }
  div {
    display: flex;
    align-items: center;
  }
  .wrapper {
    width: 45%;
  }
  :not(:first-child) {
    margin-top: 30px;
  }
  @media ${device.tablet} {
    .wrapper {
      width: 30%;
    }
    :not(:first-child) {
      margin-top: 50px;
    }
  }
`;

export const TopSectionWrapper = styled.div`
  padding: 10px;
  background-color: ${(props) => props.theme.foreground};
  margin-bottom: 20px;
  border-radius: 5px;
`;

export const PageTitle = styled.div`
  h1 {
    margin: 0;
    color: ${(props) => props.theme.textColor};
    font-size: ${desktop.medium};
    font-family: Montserrat;
    font-weight: 600;
  }
  h2 {
    margin: 0;
    color: ${(props) => props.theme.mutedColorTertiary};
    font-weight: 500;
    font-size: ${desktop.small};
    font-family: Montserrat;
  }
  .detailsLogo {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    margin: 0 0 10px 0;

    flex-grow: 0;
    flex-shrink: 0;
  }
  .leagueName {
    color: ${(props) => props.theme.mutedColorTertiary};
    font-weight: 600;
    font-size: ${desktop.normal};
    font-family: Montserrat;
  }
`;
export const FilterContainer = styled.div`
  width: 100%;
  background: ${(props) => props.theme.foreground};
  box-shadow: ${(props) => props.theme.subtleShadow};
  padding: 15px;
  .filter {
    font-size: ${desktop.small};
    color: ${(props) => props.theme.mutedColorTertiary};
    margin: 0;
    font-weight: 400;
    padding-bottom: 10px;
    border-bottom: ${(props) => `1px solid ${props.theme.mutedColorSecondary}`};
  }
`;
export const Selection = styled.div`
  /* color: ${(props) => props.theme.textColor}; */
  font-size: ${desktop.xSmall};
  border: ${(props) => `1px solid ${props.theme.accentSecondary}`};
  padding: 3px 5px;
  text-align: center;
  margin-bottom: 5px;
`;
export const Challenger = styled.div`
  /* color: ${(props) => props.theme.textColor}; */
  font-size: ${desktop.xSmall};
  border: ${(props) => `1px solid ${props.theme.accentTertiary}`};
  padding: 3px 5px;
  text-align: center;
  margin-bottom: 5px;
`;
export const Team = styled.div`
  .content {
    display: flex;
    align-items: "center";
    margin-bottom: 5px;
    .text {
      margin: 0;
      /* color: ${(props) => props.theme.textColor}; */
      font-size: ${desktop.xSmall};
    }
    .image {
      height: 15px;
      margin-right: 10px;
    }
  }
`;

export const ChatWeb = styled(Col)`
  display: none;
  @media ${device.laptop} {
    height: 90vh;
    position: sticky;
    border-right: ${(props) => `1px solid ${props.theme.mutedColorSecondary}`};
    top: 8vh;
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 4px;
      background: ${(props) => props.theme.background};
      /* display: none; */
    }
    &::-webkit-scrollbar-track {
      background: ${(props) => props.theme.foreground};
      /* display: none; */
    }
    &::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.mutedColorPrimary};
      height: 20vh;
      border-radius: 10px;
      /* display: none; */
    }
  }
`;

export const GameDetailsStyle = styled.div`
  .container {
    padding: 10px 20px;
  }
  .actionImg {
    width: 100%;
    /* height: 50%; */
  }
  .challenge {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .teamName {
    color: ${(props) => props.theme.textColor};
    font-size: ${desktop.small};
    font-weight: 500;
    margin: 0;
  }
  .versus {
    margin: 0;
    color: ${(props) => props.theme.textColor};
    font-size: ${desktop.large};
    font-weight: 500;
  }
  .teamLogo {
    height: 70px;
    margin-bottom: 10px;
  }
  .flexer {
    display: flex;
    align-items: center;
  }
  .oddsTitle {
    color: ${(props) => props.theme.textColor};
    font-size: ${desktop.small};
    text-align: center;
    font-weight: 400;
    margin: 7px 0;
  }
`;
interface IOdds {
  selected?: boolean;
}
export const HomeOddSelection = styled.div<IOdds>`
  text-align: right;
  color: ${(props) => props.theme.textColor};
  font-weight: 300;
  font-size: ${desktop.xSmall};
  border: ${(props) =>
    props.selected
      ? `1px solid ${props.theme.primaryColor}`
      : `1px solid ${props.theme.mutedColorSecondary}`};
  background-color: ${(props) => props.theme.foreground};
  border-radius: 3;
  width: 50%;
  padding: 5px;
  cursor: pointer;
`;
export const AwayOddSelection = styled.div<IOdds>`
  width: 50%;
  text-align: start;
  color: ${(props) => props.theme.textColor};
  font-weight: 300;
  background-color: ${(props) => props.theme.foreground};
  border-radius: 3;
  padding: 5px;
  cursor: pointer;

  font-size: ${desktop.xSmall};
  border: ${(props) =>
    props.selected
      ? `1px solid ${props.theme.primaryColor}`
      : `1px solid ${props.theme.mutedColorSecondary}`};
`;
