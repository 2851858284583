export const footballData = [
  {
    division: "Major League Soccer",
    divisionFlag: "https://cdn-icons-png.flaticon.com/512/824/824743.png",
    country: "United States",
    countryFlag:
      "https://www.transparentpng.com/thumb/american-flag/which-flag-has-the-best-design-3.png",
    match: [
      {
        time: "19:20",
        date: "26 Aug",
        liveStatus: true,
        homeTeam: "New York FC",
        homeScore: 1,
        homeTeamLogo: "https://cdn-icons-png.flaticon.com/512/824/824748.png",
        awayTeam: "Toronto FC",
        awayScore: 2,
        awayTeamLogo: "https://cdn-icons-png.flaticon.com/512/738/738658.png",
        inplay: {
          tips: 10,
          players: [
            "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
            "https://images.unsplash.com/photo-1604426633861-11b2faead63c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1364&q=80",
            "https://images.unsplash.com/photo-1554151228-14d9def656e4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=986&q=80",
            "https://images.unsplash.com/photo-1542909168-82c3e7fdca5c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80",
            "https://images.unsplash.com/photo-1541647376583-8934aaf3448a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",
          ],
        },
      },
      {
        time: "19:20",
        date: "26 Aug",
        liveStatus: false,
        homeTeam: "London FC",
        homeScore: 1,
        homeTeamLogo: "https://cdn-icons-png.flaticon.com/512/738/738669.png",
        awayTeam: "Barcelona FC",
        awayScore: 2,
        awayTeamLogo: "https://cdn-icons-png.flaticon.com/512/824/824720.png",
        inplay: {
          tips: 3,
          players: [
            "https://images.unsplash.com/photo-1554151228-14d9def656e4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=986&q=80",
            "https://images.unsplash.com/photo-1542909168-82c3e7fdca5c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80",
            "https://images.unsplash.com/photo-1541647376583-8934aaf3448a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",
          ],
        },
      },
      {
        time: "19:20",
        date: "26 Aug",
        liveStatus: false,
        homeTeam: "Arsenal FC",
        homeScore: 1,
        homeTeamLogo: "https://cdn-icons-png.flaticon.com/512/824/824719.png",
        awayTeam: "AC Milan FC",
        awayScore: 2,
        awayTeamLogo: "https://cdn-icons-png.flaticon.com/512/738/738671.png",
        inplay: {
          tips: 3,
          players: [
            "https://images.unsplash.com/photo-1610652492500-ded49ceeb378?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",

            "https://images.unsplash.com/photo-1541647376583-8934aaf3448a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",
            "https://images.unsplash.com/photo-1584940120743-8981ca35b012?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",
          ],
        },
      },
      {
        time: "19:20",
        date: "26 Aug",
        liveStatus: false,
        homeTeam: "Milano FC",
        homeScore: 1,
        homeTeamLogo: "https://cdn-icons-png.flaticon.com/512/1385/1385008.png",
        awayTeam: "Liverpool FC",
        awayScore: 2,
        awayTeamLogo: "https://cdn-icons-png.flaticon.com/512/824/824725.png",
        inplay: {
          tips: 6,
          players: [
            "https://images.unsplash.com/photo-1588938172737-f774f5476d2d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",
            "https://images.unsplash.com/photo-1584940120743-8981ca35b012?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",
            "https://images.unsplash.com/photo-1610652492500-ded49ceeb378?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",
            "https://images.unsplash.com/photo-1541647376583-8934aaf3448a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",
          ],
        },
      },
    ],
  },
  {
    division: "Minor League Soccer",
    divisionFlag: "https://cdn-icons-png.flaticon.com/512/1385/1385008.png",
    country: "Japan ",
    countryFlag:
      "https://www.pngfind.com/pngs/m/63-634996_japan-imperial-flag-japan-flag-hd-png-download.png",
    match: [
      {
        time: "19:20",
        date: "24 Aug",
        liveStatus: true,
        homeTeam: "O.G.C FC",
        homeScore: 0,
        homeTeamLogo: "https://cdn-icons-png.flaticon.com/512/824/824728.png",
        awayTeam: "Baller FC",
        awayScore: 0,
        awayTeamLogo: "https://cdn-icons-png.flaticon.com/512/4768/4768769.png",
        inplay: {
          tips: 5,
          players: [
            "https://images.unsplash.com/photo-1541647376583-8934aaf3448a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",
            "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
            "https://images.unsplash.com/photo-1604426633861-11b2faead63c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1364&q=80",
            "https://images.unsplash.com/photo-1554151228-14d9def656e4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=986&q=80",
            "https://images.unsplash.com/photo-1542909168-82c3e7fdca5c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80",
          ],
        },
      },
      {
        time: "19:20",
        date: "26 Aug",
        liveStatus: false,
        homeTeam: "London FC",
        homeScore: 1,
        homeTeamLogo: "https://cdn-icons-png.flaticon.com/512/738/738669.png",
        awayTeam: "Barcelona FC",
        awayScore: 2,
        awayTeamLogo: "https://cdn-icons-png.flaticon.com/512/824/824720.png",
        inplay: {
          tips: 3,
          players: [
            "https://images.unsplash.com/photo-1554151228-14d9def656e4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=986&q=80",
            "https://images.unsplash.com/photo-1542909168-82c3e7fdca5c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80",
            "https://images.unsplash.com/photo-1541647376583-8934aaf3448a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",
          ],
        },
      },
      {
        time: "19:20",
        date: "26 Aug",
        liveStatus: false,
        homeTeam: "Arsenal FC",
        homeScore: 1,
        homeTeamLogo: "https://cdn-icons-png.flaticon.com/512/824/824719.png",
        awayTeam: "AC Milan FC",
        awayScore: 2,
        awayTeamLogo: "https://cdn-icons-png.flaticon.com/512/738/738671.png",
        inplay: {
          tips: 3,
          players: [
            "https://images.unsplash.com/photo-1610652492500-ded49ceeb378?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",

            "https://images.unsplash.com/photo-1541647376583-8934aaf3448a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",
            "https://images.unsplash.com/photo-1584940120743-8981ca35b012?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",
          ],
        },
      },
      {
        time: "19:20",
        date: "26 Aug",
        liveStatus: false,
        homeTeam: "Milano FC",
        homeScore: 1,
        homeTeamLogo: "https://cdn-icons-png.flaticon.com/512/1385/1385008.png",
        awayTeam: "Liverpool FC",
        awayScore: 2,
        awayTeamLogo: "https://cdn-icons-png.flaticon.com/512/824/824725.png",
        inplay: {
          tips: 6,
          players: [
            "https://images.unsplash.com/photo-1588938172737-f774f5476d2d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",
            "https://images.unsplash.com/photo-1584940120743-8981ca35b012?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",
            "https://images.unsplash.com/photo-1610652492500-ded49ceeb378?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",
            "https://images.unsplash.com/photo-1541647376583-8934aaf3448a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",
          ],
        },
      },
    ],
  },
  {
    division: "Major League Soccer",
    divisionFlag: "https://cdn-icons-png.flaticon.com/512/824/824743.png",
    country: "United States",
    countryFlag:
      "https://www.transparentpng.com/thumb/american-flag/which-flag-has-the-best-design-3.png",
    match: [
      {
        time: "19:20",
        date: "26 Aug",
        liveStatus: true,
        homeTeam: "New York FC",
        homeScore: 1,
        homeTeamLogo: "https://cdn-icons-png.flaticon.com/512/824/824748.png",
        awayTeam: "Toronto FC",
        awayScore: 2,
        awayTeamLogo: "https://cdn-icons-png.flaticon.com/512/738/738658.png",
        inplay: {
          tips: 10,
          players: [
            "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
            "https://images.unsplash.com/photo-1604426633861-11b2faead63c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1364&q=80",
            "https://images.unsplash.com/photo-1554151228-14d9def656e4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=986&q=80",
            "https://images.unsplash.com/photo-1542909168-82c3e7fdca5c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80",
            "https://images.unsplash.com/photo-1541647376583-8934aaf3448a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",
          ],
        },
      },
      {
        time: "19:20",
        date: "26 Aug",
        liveStatus: false,
        homeTeam: "London FC",
        homeScore: 1,
        homeTeamLogo: "https://cdn-icons-png.flaticon.com/512/738/738669.png",
        awayTeam: "Barcelona FC",
        awayScore: 2,
        awayTeamLogo: "https://cdn-icons-png.flaticon.com/512/824/824720.png",
        inplay: {
          tips: 3,
          players: [
            "https://images.unsplash.com/photo-1554151228-14d9def656e4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=986&q=80",
            "https://images.unsplash.com/photo-1542909168-82c3e7fdca5c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80",
            "https://images.unsplash.com/photo-1541647376583-8934aaf3448a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",
          ],
        },
      },
      {
        time: "19:20",
        date: "26 Aug",
        liveStatus: false,
        homeTeam: "Arsenal FC",
        homeScore: 1,
        homeTeamLogo: "https://cdn-icons-png.flaticon.com/512/824/824719.png",
        awayTeam: "AC Milan FC",
        awayScore: 2,
        awayTeamLogo: "https://cdn-icons-png.flaticon.com/512/738/738671.png",
        inplay: {
          tips: 3,
          players: [
            "https://images.unsplash.com/photo-1610652492500-ded49ceeb378?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",

            "https://images.unsplash.com/photo-1541647376583-8934aaf3448a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",
            "https://images.unsplash.com/photo-1584940120743-8981ca35b012?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",
          ],
        },
      },
      {
        time: "19:20",
        date: "26 Aug",
        liveStatus: false,
        homeTeam: "Milano FC",
        homeScore: 1,
        homeTeamLogo: "https://cdn-icons-png.flaticon.com/512/1385/1385008.png",
        awayTeam: "Liverpool FC",
        awayScore: 2,
        awayTeamLogo: "https://cdn-icons-png.flaticon.com/512/824/824725.png",
        inplay: {
          tips: 6,
          players: [
            "https://images.unsplash.com/photo-1588938172737-f774f5476d2d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",
            "https://images.unsplash.com/photo-1584940120743-8981ca35b012?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",
            "https://images.unsplash.com/photo-1610652492500-ded49ceeb378?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",
            "https://images.unsplash.com/photo-1541647376583-8934aaf3448a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",
          ],
        },
      },
    ],
  },
];

export const recentUsers = [
  "https://images.unsplash.com/photo-1587844939565-ff48c0134c6f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2342&q=80",
  "https://images.unsplash.com/flagged/photo-1574874897534-036671407eda?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
  "https://images.unsplash.com/flagged/photo-1558764646-0c1bed17c001?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",
  "https://images.unsplash.com/photo-1440589473619-3cde28941638?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",
  "https://images.unsplash.com/photo-1499996860823-5214fcc65f8f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1366&q=80",
  "https://images.unsplash.com/photo-1570632267781-46f97c2a4f76?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3387&q=80",
  "",
  "https://images.unsplash.com/photo-1601035242143-8fb58d787cd1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",
  "",
  "https://images.unsplash.com/photo-1608175602069-c542f63e8d0e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2662&q=80",
  "https://images.unsplash.com/photo-1588854621349-d2f1d0ba3ece?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80",
  "",
  "https://images.unsplash.com/photo-1595501894686-4e258c802d66?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1364&q=80",

  "",
  "https://images.unsplash.com/photo-1570632267781-46f97c2a4f76?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3387&q=80",
  "https://images.unsplash.com/photo-1601035242143-8fb58d787cd1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",
];
export const notifications = [
  {
    userPicture:
      "https://images.unsplash.com/photo-1587844939565-ff48c0134c6f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2342&q=80",
    message: "Created a new challenge",
    name: "Olaoluwa Kalulu",
    time: "4:30 pm",
  },
  {
    userPicture:
      "https://images.unsplash.com/flagged/photo-1558764646-0c1bed17c001?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",
    message: "Started following",
    name: "User Kunle Kalulu",
    time: "4:30 pm",
  },
  {
    userPicture: "",
    message: "Sent a message : I will win this money today o... hehehe",
    name: "User Kunle Kalulu",
    time: "4:30 pm",
  },
  {
    userPicture:
      "https://images.unsplash.com/photo-1499996860823-5214fcc65f8f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1366&q=80",
    message: "Completed challenge",
    name: "Seun Egbegbe",
    time: "4:30 pm",
  },
  {
    userPicture: "",
    message: "Sent a message",
    name: "John Terry",
    time: "4:30 pm",
  },
];
export const lockedGames = [
  {
    name: "King Prince",
    img: "https://images.unsplash.com/photo-1615125946109-9455bffbd99c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80",
    homeClub: "https://cdn-icons-png.flaticon.com/512/738/738652.png",
    awayClub: "https://cdn-icons-png.flaticon.com/512/824/824734.png",
  },
  {
    name: "Solomon Desh",
    img: "",
    homeClub: "https://cdn-icons-png.flaticon.com/512/824/824748.png",
    awayClub: "https://cdn-icons-png.flaticon.com/512/4978/4978200.png",
  },
  {
    name: "Kingston Paul",
    img: "https://images.unsplash.com/photo-1637206616858-7e80633a6162?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",
    homeClub: "https://cdn-icons-png.flaticon.com/512/824/824743.png",
    awayClub: "https://cdn-icons-png.flaticon.com/512/824/824748.png",
  },
  {
    name: "Kingston Paul",
    img: "https://images.unsplash.com/photo-1541647376583-8934aaf3448a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",
    homeClub: "https://cdn-icons-png.flaticon.com/512/738/738662.png",
    awayClub: "https://cdn-icons-png.flaticon.com/512/824/824748.png",
  },
  {
    name: "Tolino Dickah",
    img: "",
    homeClub: "https://cdn-icons-png.flaticon.com/512/824/824748.png",
    awayClub: "https://cdn-icons-png.flaticon.com/512/4978/4978200.png",
  },
];

export const oddTypes = [
  { title: "Winner" },
  { title: "First to Score" },
  { title: "More corner kicks" },
  { title: "Loser" },
  { title: "More red cards" },
  { title: "More yellow cards" },
];
