import styled from "styled-components";
import { device } from "../../theme/breakPoints";
import { desktop, mobile } from "../../theme/fontSize";

export const SuccessText = styled.p`
  margin: 0;
  text-align: center;
  color: ${(props) => props.theme.accentSecondary};
  font-weight: 500;
  font-size: ${mobile.medium};

  @media ${device.desktop} {
    font-size: ${desktop.medium};
  }
`;
export const Divider = styled.hr`
  border: ${(props) => `1px dashed ${props.theme.mutedColorSecondary}`};
`;
export const DetailsText = styled.p`
  color: ${(props) => props.theme.mutedColorTertiary};
  margin: 0;
  text-align: start;
  /* font-size: ${mobile.normal};
  font-weight: 400; */

  @media ${device.desktop} {
    /* font-size: ${desktop.normal}; */
  }
`;
export const ModalText = styled.h4`
  font-size: 16px;
  text-align: center;
  color: ${(props) => props.theme.textColor};
  margin: 0;
  span {
    font-weight: 600;
    color: ${(props) => props.theme.accentSecondary};
  }
  @media ${device.laptop} {
    font-size: ${desktop.normal};
  }
`;
export const CloseModal = styled.div`
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
  font-size: 16px;
  color: ${(props) => props.theme.accentPrimary};
  /* margin-right: 10px; */
`;

export const Selected = styled.div`
  font-size: 15px;
  display: flex;
  margin: 0;
  color: ${(props) => props.theme.accentSecondary};
  /* margin-right: 10px; */
`;

export const AddMoreText = styled.p`
  cursor: pointer;
  font-size: 16px;
  color: ${(props) => props.theme.accentTertiary};
  font-size: ${mobile.small};
  margin-top: 15px;
  @media ${device.laptop} {
    font-size: ${desktop.small};
  }
`;
export const PaymentContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${(props) => `1px solid ${props.theme.mutedColorSecondary}`};
  margin: 10px 0;
  cursor: pointer;
`;
// export const DetailsText = styled[Symbo].p `

// `
