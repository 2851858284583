//@ts-nocheck

import moment from "moment";
import { NotificationAlert } from "./NotificationAlert";

export function formatNumberAmount(amount: number): string {
  const formatter = amount
    ?.toFixed(2)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  return formatter;
}

export const updateMatchArray = (
  gamesList: any,
  formattedLeagues: any,
  live?: any
) => {
  const liveMatchArray = {} as any;
  const finalData = [];
  for (const data of gamesList) {
    if (liveMatchArray[data?.apiFootballLeagueId]) {
      liveMatchArray[data?.apiFootballLeagueId].push(data);
    } else {
      liveMatchArray[data?.apiFootballLeagueId] = [data];
    }
  }
  for (const data of Object.entries(liveMatchArray) as any) {
    const currentLeague = formattedLeagues[data[0]];
    finalData.push({
      division: currentLeague?.leagueName,
      divisionFlag: currentLeague?.image,
      country: currentLeague?.country,
      countryFlag: "",
      match: [
        ...data[1]?.map((matchData: any) => ({
          liveStatus: getLiveStatus(matchData?.status),
          time: moment(matchData?.firstPeriod).format("hh:mm a"),
          date: moment(matchData?.firstPeriod).format("DD MMM"),
          homeTeam: matchData?.homeTeam?.name,
          gameTime: matchData?.elapsed,
          homeScore: matchData?.goals?.home,
          homeTeamLogo: matchData?.homeTeam?.logo,
          awayTeam: matchData?.awayTeam?.name,
          awayScore: matchData?.goals?.away,
          awayTeamLogo: matchData?.awayTeam?.logo,
          gameType: matchData?.gameType,
          others: { ...matchData },
        })),
      ],
    });
  }
  return finalData;
};
const restructureGame = (data: any[]) => {
  // console.log("data", data);
  const struct = {};
  data?.forEach((newData) => {
    // console.log("newData", newData);
    // console.log("new date", newData.date);

    // console.log("new Data", struct[newData?.date]);
    // console.log("the struct", struct);
    if (struct[newData?.date]) {
      // console.log("date from data 1", newData?.date);
      struct[newData?.date] = [...struct[newData.date], newData];
    } else {
      // console.log("date from data 2", newData?.date);
      struct[newData.date] = [newData];
    }
  });
  return struct;
};

export const updateMatchArrayByDate = (
  gamesList: any,
  formattedLeagues: any,
  liveGamesObject?: any
) => {
  const liveMatchArray = {} as any;
  const finalData = [];

  for (const data of gamesList) {
    if (liveMatchArray[data?.apiFootballLeagueId]) {
      liveMatchArray[data?.apiFootballLeagueId].push(data);
    } else {
      liveMatchArray[data?.apiFootballLeagueId] = [data];
    }
  }
  for (const data of Object.entries(liveMatchArray) as any) {
    const currentLeague = formattedLeagues[data[0]];
    finalData.push({
      division: currentLeague?.leagueName,
      divisionFlag: currentLeague?.image,
      country: currentLeague?.country,

      countryFlag: "",
      match: restructureGame(
        data[1]?.map((matchData: any) => ({
          // liveStatus: liveGamesObject[matchData?.apiFootballFixtureId]
          //   ? true
          //   : false,
          liveStatus: getLiveStatus(matchData?.status),
          time: moment(matchData?.firstPeriod).format("hh:mm a"),
          date: moment(matchData?.firstPeriod).format("DD MMMM yyyy"),
          homeTeam: matchData?.homeTeam?.name,
          gameTime: matchData?.elapsed,
          homeScore: matchData?.goals?.home,
          homeTeamLogo: matchData?.homeTeam?.logo,
          awayTeam: matchData?.awayTeam?.name,
          awayScore: matchData?.goals?.away,
          awayTeamLogo: matchData?.awayTeam?.logo,
          gameType: matchData?.gameType,
          others: { ...matchData },
        }))
      ),
    });
  }
  return finalData;
};

export const mapChallengeKey = (key: string) => {
  const val: { [key: string]: string } = {
    SHOTSONGOAL: "Shots on Goal",
    SHOTSOFFGOAL: "Shots off Goal",
    SHOTSINSIDEBOX: "Shots insidebox",
    SHOTSOUTSIDEBOX: "Shots outsidebox",
    TOTALSHOTS: "Total Shots",
    BLOCKEDSHOTS: "Blocked Shots",
    FOULS: "Fouls",
    CORNERKICKS: "Corner Kicks",
    OFFSIDES: "Offides",
    BALLPOSSESSION: "Ball Possession",
    YELLOWCARD: "Yellow Cards",
    REDCARD: "Red Cards",
    GOALKEEPERSAVES: "Goalkeeper Saves",
    TOTALPASSES: "Total passes",
    PASSESACCURATE: "Passes Accurate",
    PASSES: "Passes %",
  };
  return val[key];
};

const getLiveStatus = (status: string) => {
  switch (status) {
    case "1H":
    case "2H":
    case "HT":
    case "LIVE":
    case "INT":
    case "ET":
    case "BT":
    case "P":
    case "SUSP":
      return true;
    default:
      return false;
  }
};


export const handleCopyClick = (copyId: string | null) => {
  navigator.clipboard.writeText(copyId);
  NotificationAlert("success", "Copied Successfully",);
};
