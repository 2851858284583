import { Col, Collapse, Row } from "antd";
import styled from "styled-components";
import { device } from "../../theme/breakPoints";
import bg from "../../asset/bg.jpg";
import { desktop, mobile } from "../../theme/fontSize";

export const Container = styled.div`
  position: relative;
  width: 100%;
  top: 0;
  opacity: 1;
  height: 100vh;
  filter: grayscale(0);
  /* background: linear-gradient(
      90deg,
      ${(props) => props.theme.authBackground + "cc"},
      ${(props) => props.theme.authBackground + "dd"},
      ${(props) => props.theme.authBackground + "ee"},
      ${(props) => props.theme.authBackground + "ff"},
      ${(props) => props.theme.authBackground}
    ),
    url(${bg}); */
  padding: 50px 10px;
  overflow-y: auto;

  @media ${device.laptop} {
    padding: 8vw;
  }
`;

export const LogoContainer = styled.div`
  /* display: flex; */
  z-index: 1000;
  top: 10px;
  left: 10px;
  position: fixed;
  cursor: pointer;
  /* z-index: 100; */
  img {
    height: 30px;
    margin-right: 10px;
  }
  h1 {
    margin: 0;
    font-weight: 600;
    font-family: montserrat;
    color: ${(props) => props.theme.primaryColor};
  }
  @media ${device.laptop} {
    img {
      height: 2vw;
      margin-right: 10px;
    }
  }
`;

export const StyledCol = styled(Col)`
  .title {
    text-transform: uppercase;
    margin: 0;
    color: ${(props) => props.theme.primaryColor};
    font-size: ${mobile.medium};
    letter-spacing: 1px;
  }
  .subtitle {
    font-size: ${mobile.large};
    font-weight: 600;
    margin-bottom: 15px;
    color: ${(props) => props.theme.textColor};
  }
  .accordionText {
    color: ${(props) => props.theme.textColor};
  }
  @media ${device.laptop} {
    .title {
      font-size: ${desktop.medium};
    }
    .subtitle {
      font-size: ${desktop.extraLarge};
    }
  }
`;

export const StyledRow = styled(Row)`
  padding: 30px 0px;
  height: 100%;

  @media ${device.laptop} {
    padding: 4vw 8vw;
  }
`;
export const StyledCollapse = styled(Collapse)`
  background: transparent;
  margin-bottom: 30px !important;
  border: ${(props) => `1px solid ${props.theme.mutedColorTertiary}`};
  border: none !important;
  padding-left: 0 !important;

  .ant-collapse-content-box {
    background: transparent !important;
    border: none !important;
    padding-left: 0px !important;
  }
  .ant-collapse-item,
  .ant-collapse-content-active {
    border: none;
    padding-left: 0px !important;
  }
  svg,
  .ant-collapse-header {
    color: ${(props) => props.theme.textColor} !important;
    font-weight: 500;
    font-size: ${mobile.normal};
    border: none;
    padding-left: 0px !important;
  }
  @media ${device.laptop} {
    .ant-collapse-header {
      font-size: ${desktop.normal};
    }
  }
`;

export const StyledPanel = styled(Collapse.Panel)`
  background: transparent !important;
  border: none;
  margin-bottom: 10px;
`;
interface PlanProps {
  active?: boolean;
}
export const PlanContainer = styled.div<PlanProps>`
  margin: 0 0 15px 0;
  display: flex;
  padding: 15px;
  background: ${(props) => props.theme.foreground};
  box-shadow: ${(props) => props.theme.authShadow};
  border-radius: 10px;
  cursor: pointer;
  border: ${(props) =>
    `1px solid ${
      props.active ? props.theme.primaryColor : props.theme.foreground
    }`};
  .ant-radio-checked .ant-radio-inner {
    border-color: ${(props) => props.theme.primaryColor} !important ;
  }

  .ant-radio-checked .ant-radio-inner:after {
    background-color: ${(props) => props.theme.primaryColor} !important;
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: ${(props) => props.theme.primaryColor} !important;
  }
  .header {
    margin: 0;
    color: ${(props) => props.theme.textColor};
    font-size: ${mobile.normal};
    letter-spacing: 1px;
    font-weight: 600;
  }
  .description {
    font-size: ${mobile.small};
    margin: 0;
    margin-bottom: 15px;
    color: ${(props) => props.theme.textColor};
  }
  @media ${device.laptop} {
    .header {
      font-size: ${desktop.normal};
    }
    .description {
      font-size: ${desktop.small};
      width: 85%;
    }
  }
`;
