import styled from "styled-components";
import { desktop } from "../../theme/fontSize";

export const ThirdColumn = styled.div`
  .flexer {
    display: flex;
    justify-content: space-between;
  }
  .balance {
    margin: 0;
    font-family: Montserrat;
    font-weight: 500;
    color: ${(props) => props.theme.textColor};
    font-size: ${desktop.normal};
  }
  .balanceValue {
    font-family: Montserrat;
    margin: 0;
    font-weight: 500;
    color: ${(props) => props.theme.textColor};
    font-size: ${desktop.large};
  }
  .lockedChallenge {
    width: 100%;
    padding: 10px;
    background-color: ${(props) => props.theme.foreground};
    border-radius: 7px;
    box-shadow: ${(props) => props.theme.subtleShadow};
    max-height: 200px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 4px;
      background: ${(props) => props.theme.foreground};
    }
    &::-webkit-scrollbar-track {
      background: ${(props) => props.theme.foreground};
      /* display: none; */
    }
    &::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.mutedColorPrimary};
      height: 100px;
      border-radius: 10px;
      /* display: none; */
    }
  }
  .title {
    font-size: ${desktop.small};
    font-weight: 500;
    color: ${(props) => props.theme.textColor};
    font-family: Montserrat;
    margin: 0;
  }
  .notifyName {
    font-size: ${desktop.xSmall};
    font-weight: 500;
    color: ${(props) => props.theme.textColor};
    margin: 0;
  }
  .notifyTimeAndMessaege {
    font-size: ${desktop.xSmall};
    font-weight: 400;
    color: ${(props) => props.theme.mutedColorSecondary};
    margin: 0;
  }
  .notifyMessage {
    /* color: ${(props) => props.theme.accentSecondary}; */
  }
  .lockedName {
    font-size: ${desktop.xSmall};
    font-weight: 400;
    color: ${(props) => props.theme.mutedColorTertiary};
    margin: 0;
  }
  .lockedLeagueImage {
    height: 15px;
    margin: 0 10px;
  }
`;
