import React from "react";
import { ModalComponent } from "../common/ModalComponent";
import { NotificationText } from "../common/StyleComponents";
import { Formik } from "formik";
import * as yup from "yup";
import TextInput from "../common/TextInput";
import { ButtonComponent } from "../common/Button";
import {
  RegisterSelector,
  useAppDispatch,
  resetPasswordAction,
} from "@michaelojo/vermo-redux";
import { NotificationAlert } from "../../utils/NotificationAlert";
interface ResetPasswordModalInterface {
  onCancel: () => void;
  visible: boolean;
  onSubmit?: () => void;
  email?: any;
}
interface Values {
  token?: any;
  password?: any;
}
const ResetPassword: React.FC<ResetPasswordModalInterface> = ({
  email,
  onCancel,
  visible,
  children,
  onSubmit,
}) => {
  const [loading, setLoading] = React.useState(false);
  const dispatcher = useAppDispatch();
  const registerSelelctor = RegisterSelector() as any;
  const resetPassword = (values: Values) => {
    setLoading(true);
    let userValues = {
      email: registerSelelctor?.registerationInfo?.email,
      token: String(values.token),
      password: values.password,
    };
    dispatcher(resetPasswordAction(userValues))
      .unwrap()
      .then((originalPromiseResponse) => {
        setLoading(false);
        console.log("response", originalPromiseResponse);
        if (originalPromiseResponse?.resetPassword?.status === false) {
          NotificationAlert(
            "error",
            "Error",
            originalPromiseResponse?.resetPassword?.msg
          );
        } else {
          NotificationAlert(
            "success",
            "Success",
            originalPromiseResponse?.resetPassword?.msg
          );
          onCancel();
        }
      })
      .catch((err) => {
        setLoading(false);
        NotificationAlert("error", "Error", err.message);
        // console.log("err", err.message);
      });
  };

  return (
    <>
      <ModalComponent onCancel={onCancel} visible={visible}>
        <div style={{ padding: "1vw", width: "100%" }}>
          <NotificationText>
            A reset password token has been sent to
            <span> {registerSelelctor?.registerationInfo?.email} </span>, kindly
            copy and paste below
          </NotificationText>
          <br />
          <Formik
            onSubmit={(values: Values) => {
              resetPassword(values);
            }}
            initialValues={{
              token: "",
              password: "",
            }}
            validationSchema={yup.object().shape({
              token: yup
                .number()
                .required("Please provide your reset password token"),
              password: yup
                .string()
                .required("Please provide your new password."),
            })}
          >
            {({ values, handleChange, errors, handleSubmit }) => (
              <>
                <TextInput
                  required
                  inputType="number"
                  value={values.token}
                  name="token"
                  placeholder="Enter your reset token."
                  onChange={handleChange}
                  error={errors.token}
                  label="Reset Token"
                />
                <TextInput
                  required
                  inputType="password"
                  value={values.password}
                  name="password"
                  placeholder="Enter your reset new password"
                  onChange={handleChange}
                  error={errors.password}
                  label="New Password"
                />
                <ButtonComponent
                  loading={loading}
                  onClick={handleSubmit}
                  text={"Reset Password"}
                  style={{ marginTop: "10px" }}
                />
              </>
            )}
          </Formik>
          <br />
        </div>
      </ModalComponent>
    </>
  );
};

export default ResetPassword;
